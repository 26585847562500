import React from "react";
import Card from "react-bootstrap/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleChevronRight} from "@fortawesome/free-solid-svg-icons";
import BannerImg  from "../../assets/img/homeBody/restaurantCollection.png";
import Placeholder from 'react-bootstrap/Placeholder';

const BannersCard= ({widthValue, heightValue, img, title,borderRadius, background}) => {
    return (
        <Card style={{width: widthValue, height: heightValue, background: background, borderRadius: borderRadius, position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: background, borderRadius: borderRadius}}></div>
            <Card.Img src={img?img:BannerImg} alt="Card image" style={{borderRadius: borderRadius}}/>
            <Card.ImgOverlay style={{marginLeft: '25px', marginRight: '25px'}} className='d-flex justify-content-between align-items-end'>
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <Card.Title className='banners-title' style={{fontWeight: '700', fontSize: '32px', lineHeight: '38px'}}>{title}</Card.Title>
                    {
                        !title && <Placeholder as={Card.Title} xs={8} className='banners-title' style={{fontWeight: '700', fontSize: '32px', lineHeight: '38px'}}/>
                    }
                    <div className='d-flex right-arrow' style={{marginBottom: '0.5rem'}}>
                        <FontAwesomeIcon  className='w-100 h-100' icon={faCircleChevronRight}/>
                    </div>
                </div>
            </Card.ImgOverlay>
        </Card>
    );
}

BannersCard.defaultProps = {
    widthValue: '417px',
    heightValue: 'auto',
    // heightValue: '222px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 55.52%, rgba(0, 0, 0, 0.7) 99.1%)',
    filter: 'drop-shadow(-5px 11px 10px rgba(0, 0, 0, 0.15))',
    borderRadius: '36px',
}

export default BannersCard;