import React from "react";
import Card from "react-bootstrap/Card";
import './css/moneyRangeBannerStyle.css'
import _map from "lodash/map";

const CategoryCard= ({widthValue, heightValue, title, borderRadius, fontWeight, fontSize, lineHeight, color, fontStyle, borderColor, categories, getCategoryOnChange}) => {
    return (
        <Card style={{width: widthValue, height: heightValue, borderRadius: borderRadius, borderColor: borderColor}} className="mt-3 mb-3">
            <div className='d-flex flex-column'>
                <Card.Title className='ms-4 me-4 mt-4'>
                    <label className='banners-title' style={{fontWeight: fontWeight, fontSize: fontSize, lineHeight: lineHeight, color: color, fontStyle: fontStyle}}>{title}</label>
                </Card.Title>
                <div className='ms-4 me-4 row'>
                    {
                        _map(categories, (value, index) => {
                            return <div className='col-6' key={'category-' + index}>
                                <div className="d-flex justify-content-between mt-3 mb-3">
                                    <div className="d-flex">
                                        <input type="checkbox" className="filter-checkbox me-2" name="category_id" value={value.id} onChange={getCategoryOnChange}/>
                                        <label className="filter-order-options">{value.name}</label>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </Card>
    );
}

CategoryCard.defaultProps = {
    widthValue: '100%',
    heightValue: 'auto',
    // heightValue: '222px',
    filter: 'drop-shadow(-5px 11px 10px rgba(0, 0, 0, 0.15))',
    borderRadius: '30px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#000000',
    borderColor: '#E2E2E2'
}

export default CategoryCard;