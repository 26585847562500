import React from "react";
import styled from "styled-components";

const BaseSelectRound = styled.select`
  border: 1px solid ${props => props.invalid ? '#FA0606FF' : '#E2E2E2'};
  border-radius: 30px;
  width: 179px;
  height: 57px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  &:focus {
    box-shadow: none;
    border-color: ${props => props.invalid ? '#FA0606FF' : '#E2E2E2'};
  }
`

const SelectRound = ({onChange, showErrors, ...props}) => {

    const handleOnChange = (e) => {
        e.preventDefault();
        console.log(e)
        onChange(e.target.value)
    }

    return <BaseSelectRound {...props} invalid={showErrors} onChange={handleOnChange}>
        {props.children}
    </BaseSelectRound>
}

export default SelectRound;