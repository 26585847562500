import axios from "axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {isNull, isUndefined} from "lodash";

export class DefaultService {
    translation = useTranslation('auth')

    params = {}
    axios = null

    constructor() {
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        if (!isNull(user)) {
            const access_token = user['access_token']
            const user_id = user['user_id']
            Object.assign(this.params, this.params, {user_id, access_token})
        }

    }

    get(uri, params) {
        const data = Object.assign({}, this.params, params)
        return this.axios.get(uri, {params: data}).then(res => {
            return res.data
        })
    }

    post(uri, params = {}) {
        let data = Object.assign({}, {}, params)
        if (uri.indexOf('login') === -1) {
            data = Object.assign({}, data, this.params)
        }
        return this.axios.post(uri, data).then(res => {
            return res.data
        }).catch(err => {
            if (isUndefined(err.response) || err.response.status !== 422) {
                toast.error(this.translation.t('common:requestError'));
            }
            throw err
        })
    }

    postFormData(uri, params = {}) {
        const formData = new FormData();
        for (let key in params) {
            formData.append(key, params[key]);
        }
        return this.axios({
            method: "post",
            url: uri,
            data: formData,
            headers: {"Content-Type": "multipart/form-data"},
        }).then(function (res) {
            return res.data
        }).catch(err => {
            if (isUndefined(err.response) || err.response.status !== 422) {
                toast.error(this.translation.t('common:requestError'));
            }
            throw err
        })
    }


}