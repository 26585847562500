import React from 'react';
import RestaurantCategoryCard from "../cards/restaurantCategoryCard";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import './css/restaurantCategoryCarrousel.css'

const RestaurantCategoryGallery = ({restaurantCategories,keyName}) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 12
        },
        desktop: {
            breakpoint: { max: 2999, min: 1400 },
            items: 10
        },
        tablet: {
            breakpoint: { max: 1399, min: 1080 },
            items: 7
        },
        miniTablet: {
            breakpoint: { max: 1079, min: 800 },
            items: 5
        },
        largeMobile: {
            breakpoint: { max: 799, min: 600 },
            items: 4
        },
        mobile: {
            breakpoint: { max: 599, min: 0 },
            items: 3
        }
    };
    return (
        <Carousel
            key={keyName}
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={false}
            // autoPlay={this.props.deviceType !== "mobile"}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container w-100 h-100"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="d-flex justify-content-center"
        >
            {
                _map(_isEmpty(restaurantCategories)?Array(3):restaurantCategories, (value,index) => {
                    return <div className='d-flex restaurantCategory' key={keyName+'-'+index}>
                        <RestaurantCategoryCard img={value?value.image:null} title={value?value.name:null}></RestaurantCategoryCard>
                    </div>
                })
            }
        </Carousel>
    )
}

export default RestaurantCategoryGallery;