import {DefaultService} from "./DefaultService";

class App extends DefaultService {
    pagesSettings(){
        return this.get('/pages-settings').then(res => res.data)
    }

    helpSettings(params){
        return this.post('/help',params).then(res => res.help)
    }
}

export default App;