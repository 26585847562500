import React from "react";
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from "../../context/auth/AuthContext";
import {useParseRoute} from "../../hooks/parseRoute";

const Unauthenticated = () => {
    const {user} = useAuth();
    const routeHome = useParseRoute('customer')


    if (user) {
        return <Navigate to={routeHome}/>;
    }

    return <>
        <Outlet/>
    </>
}

export default Unauthenticated;