import React from "react";

const CirclesWithImgAndColor = ({widthValue, heightValue, backgroundValue, imgValue,styleCircle, styleImg, ...props}) => {
    return <div className="d-flex justify-content-center align-items-center rounded-circle"
                style={{width: widthValue, height: heightValue, background: backgroundValue, ...styleCircle}}>
            <img src={imgValue} alt="Icon Circle" style={styleImg} />
    </div>

}

CirclesWithImgAndColor.defaultProps = {
    widthValue: "114.83px",
    heightValue: "114.83px",
    backgroundValue: "#FF6501",
}


export default CirclesWithImgAndColor;