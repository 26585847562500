import {DefaultService} from "./DefaultService";

class StoreDetailService extends DefaultService {
    addFavoriteStore(params){
        return this.post('/customer/delivery/add-favourite-store', params).then(res => res.data)
    }

    getStoreList(params){
        return this.post('/customer/delivery/store-list', params).then(res => res.store_list)
    }
}

export default StoreDetailService;