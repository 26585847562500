import React, {useEffect} from "react";
import styled from "styled-components";
import RestaurantCategoryGallery from "../carousel/restaurantCategoryCarousel";
import BannersGallery from "../carousel/bannersCarousel";
import {useCustomer} from "../../context/customer/CustomerContext";
import RestaurantCollectionGallery from "../carousel/restaurantCollectionCarousel";
import _map from "lodash/map";
import FilterOrder from "../filters/filterOrder";
import AllStoresComponent from "../allStores/allStores";
import Placeholder from "react-bootstrap/Placeholder";
import Card from "react-bootstrap/Card";
import _isEmpty from "lodash/isEmpty";

const BodyContainer = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  width: 100%;
  min-height: 264px;
  color: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 27px 45px 27px;

  @media (max-width: 600px) {
    height: auto;
    & > div {
      flex-direction: column;
    }

    & > div > div {
      align-self: center;
      margin-top: 10px;
    }
  }
`

const Banners = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  width: 100%;
  //min-height: 264px;
  color: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 600px) {
    height: auto;
    & > div {
      flex-direction: column;
    }

    & > div > div {
      align-self: center;
      margin-top: 10px;
    }
  }
`

const RestaurantCategory = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  width: 100%;
  //min-height: 264px;
  color: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 40px 0;

  @media (max-width: 600px) {
    height: auto;
    & > div {
      flex-direction: column;
    }

    & > div > div {
      align-self: center;
      margin-top: 10px;
    }
  }
`

const Recommended = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  width: 100%;
  //min-height: 264px;
  color: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .restaurant-collection {
    margin-left: 70px;

    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
  }

  @media (max-width: 820px) {
    .restaurant-collection {
      font-size: 25px;
      margin-left: 40px;
    }
  }

  @media (max-width: 600px) {
    height: auto;
    & > div {
      flex-direction: column;
    }

    & > div > div {
      align-self: center;
      margin-top: 10px;
    }
  }
`

const StoreDetails = styled.div`
  //font-weight: 300;
  //font-size: 16px;
  //line-height: 19px;
  //text-transform: uppercase;
  //width: 100%;
  ////min-height: 264px;
  //color: #E2E2E2;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  flex-direction: row;

`

const StoreFilters = styled.div`
  //font-weight: 300;
  //font-size: 16px;
  //line-height: 19px;
  //text-transform: uppercase;
  //width: 100%;
  ////min-height: 264px;
  //color: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const AllStores = styled.div`
  //font-weight: 300;
  //font-size: 16px;
  //line-height: 19px;
  //text-transform: uppercase;
  //width: 100%;
  ////min-height: 264px;
  //color: #E2E2E2;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //flex-direction: row;
`


const HomeBody = () => {
    const {
        getBanner,
        banners,
        getRestaurantCategories,
        restaurantCategories,
        getRestaurantCollection,
        restaurantCollections
    } = useCustomer()

    useEffect(() => {
        getBanner()
        getRestaurantCategories()
        getRestaurantCollection()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        <BodyContainer>
            <Banners className="w-100 h-auto d-flex">
                <BannersGallery banners={banners}></BannersGallery>
            </Banners>
            <RestaurantCategory className="w-100 h-auto d-flex">
                {
                    <RestaurantCategoryGallery keyName="restaurant-categories" key="contaienr-restaurant-categories"
                                               restaurantCategories={restaurantCategories}/>
                }
            </RestaurantCategory>
            <Recommended className="w-100 h-auto d-flex">
                {
                    _map(_isEmpty(restaurantCollections)?Array(1):restaurantCollections, (value) => {
                        return <div className="w-100" key={value?value.id:1}>
                            <label className="h-auto d-flex align-self-start restaurant-collection">
                                {value?value.name:null}
                            </label>
                            {
                                !value && <Placeholder xs={2} className="h-auto d-flex align-self-start restaurant-collection"/>
                            }
                            <div className="w-100 h-auto d-flex" style={{padding: '20px 0 40px 0'}}>
                                <RestaurantCollectionGallery keyName={"restaurant-collection-" + 1}
                                                             restaurantCollections={value?value['store-details']:null}/>
                            </div>
                        </div>
                    })
                }
            </Recommended>
            <StoreDetails className="w-100 h-auto d-flex">
                <StoreFilters className="col-4">
                    <FilterOrder></FilterOrder>
                </StoreFilters>
                <AllStores className="col-8">
                    <AllStoresComponent></AllStoresComponent>
                </AllStores>
            </StoreDetails>
        </BodyContainer>
    </>
}

export default HomeBody;