import React, {useEffect, useMemo, useState} from "react";
import {useApp} from "../../../context/app/AppContext";
import styled from "styled-components";
import PictureAboutUs from "../../../assets/img/home/pictureAboutUs.png"
import {useTranslation} from "react-i18next";
import ButtonYellow from "../../../components/buttonYellow";
import {isNull} from "lodash";

const AboutUsContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 711px;
    height: 432px;
  }

  .about-us {
    padding-left: 53px;
  }

  .about-us button {
    margin-top: 35px;
  }

  .about-us-body {
    max-width: 90%;
  }

  .about-us-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }

  @media (max-width: 1422px) {
    img {
      width: 611px;
      height: 432px;
    }
    
    .about-us {
      padding-left: 16px;
    }

    .about-us button {
      margin: 10px 0;
    }

    .about-us-body {
      max-width: 95%;
    }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    img {
      width: 100%;
      height: 432px;
      object-fit: cover;
    }

    .about-us {
      margin-top: 16px;
      padding-left: 10px;
      min-width: 100%;
    }

    .about-us button {
      margin: 20px 0;
    }
  }
`

const AboutUs = () => {
    const {t} = useTranslation(['home'])
    const {pagesSettings, getPagesSettings, language} = useApp();
    const [bodyAboutUs, setBodyAboutUs] = useState('')
    const [titleAboutUs, setTitleAboutUs] = useState('')


    useEffect(() => {
        getPagesSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isNull(pagesSettings)) {
            pagesSettings.map(item => {
                if (item.slug === 'about-us') {
                    setBodyAboutUs(language === 'es' ? item.es_description : item.en_description)
                    setTitleAboutUs(language === 'es' ? item.es_name : item.en_name)
                }
                return item
            })

        }
    }, [pagesSettings, language])


    return <AboutUsContainer>
        <img src={PictureAboutUs} alt="About Us"/>
        {
            useMemo(() => {
                return <div className="w-100 h-100 about-us">
                    <label className="about-us-title">{titleAboutUs}</label>
                    <div className="about-us-body" dangerouslySetInnerHTML={{__html: bodyAboutUs}}></div>
                    <ButtonYellow>{t('viewServices')}</ButtonYellow>
                </div>
            }, [titleAboutUs, bodyAboutUs, t])
        }
    </AboutUsContainer>

}

export default AboutUs;