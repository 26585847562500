import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18n from './i18n/i18n';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import './index.css';
import {AppContextProvider} from "./context/app/AppContext";
import {AreaContextProvider} from "./context/area/AreaContext";
import {AuthContextProvider} from "./context/auth/AuthContext";
import {ToastContainer, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <AppContextProvider>
                <AuthContextProvider>
                    <AreaContextProvider>
                        <App/>
                        <ToastContainer position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick={false}
                                        rtl={false}
                                        pauseOnFocusLoss={false}
                                        draggable
                                        pauseOnHover
                                        transition={Flip}
                                        theme="colored"/>
                    </AreaContextProvider>
                </AuthContextProvider>
            </AppContextProvider>
        </I18nextProvider>
    </React.StrictMode>
);

reportWebVitals();
