import React, {useEffect, useState} from "react";
import LogoM from "../../../assets/img/logo/logo_m.ico";
import CardWithLogo from "../../../components/cardWithLogo";
import {useTranslation} from "react-i18next";
import CardWithText from "../../../components/cardWithText";
import styled from "styled-components";
import UploadProfile from "../../../components/uploadProfile";
import InputRound from "../../../components/inputRound";
import ButtonWhite from "../../../components/buttonWhite";
import SelectRound from "../../../components/selectRound";
import DatepickerRound from "../../../components/datepickerRound";
import {useAuth} from "../../../context/auth/AuthContext";
import {useNavigate} from "react-router-dom";
import {useApp} from "../../../context/app/AppContext";

const ContainerRegister = styled.div`
  width: 1032px;
  min-height: 838px;
  margin: 60px auto 118px auto;

  .textInformation {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #969696;
  }

  .uploadAvatar {
    padding-left: 72px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
    padding: 0 10px;

    .uploadAvatar {
      padding-left: 10px;
    }
  }

`
const ContainerCard = styled.div`
  width: 462px;
  height: auto;
  margin: 0 12px;

  .cardInputs {
    margin-bottom: 25px !important;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 0 10px;
    .base-div {
      margin: 25px 10px !important;
    }
  }
`

const FormRegister = () => {
    const {t} = useTranslation(['auth', 'common'])
    const [avatar, setAvatar] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [codeRefer, setCodeRefer] = useState('')
    const [gender, setGender] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const {register, error, setError, contactNumberRegister} = useAuth()
    const navigate = useNavigate();
    const {language} = useApp()

    useEffect(() => {
        if (contactNumberRegister.length === 0) {
            setError(null)
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleRegister = () => {
        setIsLoading(true)
        register({
            'first_name': firstName,
            'last_name': lastName,
            'email': email,
            'password': password,
            'passwordConfirmation': passwordConfirmation,
            'referral_code': codeRefer,
            'login_device': 3,
            'select_currency': 'CUP',
            'gender': gender,
            'date_of_birth': dateOfBirth,
            'profile_image': avatar,
            'device_token': '123456789',
            'select_language': language
        }).then(() => {
            navigate('/')
        }).catch(() => {
            setIsLoading(false)
        })
    }

    return <div className="container d-flex justify-content-center">
        <ContainerRegister>
            <CardWithLogo logo={LogoM} className="d-flex flex-column" width={100} height={100} measurementUnit='%'>
                <div className="w-75 text-center  mx-auto fs-2"
                     style={{fontWeight: 700, width: '400px', lineHeight: "38px"}}>
                    {t('auth:register.completeYourRegistration')}
                </div>
                <span className="mx-auto" style={{fontWeight: 400, lineHeight: "23px"}}>
                    {t('auth:register.fieldsRequire')}
                </span>
                <div className="w-100 mt-5 d-flex flex-wrap justify-content-center">
                    <ContainerCard>
                        <CardWithText textContent={t("auth:register.fields.photo")} measurementUnit={'%'} width="100"
                                      height="0"
                                      className="d-flex justify-content-start h-auto uploadAvatar cardInputs m-0">
                            <UploadProfile style={{margin: '25px'}} onChange={setAvatar}/>
                            <div className="h-auto w-50 d-flex">
                                {avatar !== '' ?
                                    <span className="my-auto textInformation text-truncate">{avatar.name}</span> :
                                    <span
                                        className="m-auto textInformation">{t("auth:register.fields.photoPlaceholder")}</span>}
                            </div>
                        </CardWithText>
                        <CardWithText textContent={t("auth:register.fields.firstAndLastName")} measurementUnit={'%'}
                                      width="100" height="0"
                                      style={{margin: 0, padding: '34px'}}
                                      className="d-flex justify-content-center flex-column h-auto cardInputs m-0">
                            <InputRound className="mx-auto" placeholder={t("auth:register.fields.firstNamePlaceholder")}
                                        showErrors={error && (Object.keys(error).includes('first_name') || Object.keys(error).includes('common'))}
                                        type="text"
                                        onChange={setFirstName}/>
                            {error && (Object.keys(error).includes('first_name') || Object.keys(error).includes('common')) &&
                                <span
                                    className="invalid-feedback text-center">* {error['first_name'] || error['common']}</span>}
                            <InputRound className="mx-auto" placeholder={t("auth:register.fields.lastNamePlaceholder")}
                                        showErrors={error && (Object.keys(error).includes('last_name') || Object.keys(error).includes('common'))}
                                        onChange={setLastName} type="text" style={{marginTop: '23px'}}/>
                            {error && (Object.keys(error).includes('last_name') || Object.keys(error).includes('common')) &&
                                <span
                                    className="invalid-feedback text-center">* {error['last_name'] || error['common']}</span>}
                        </CardWithText>
                        <CardWithText textContent={t("auth:register.fields.email")} measurementUnit={'%'} width="100"
                                      height="0"
                                      style={{margin: 0, padding: '34px'}}
                                      className="d-flex justify-content-center flex-column h-auto cardInputs m-0">
                            <InputRound className="mx-auto" placeholder={t("auth:register.fields.emailPlaceholder")}
                                        type="email"
                                        showErrors={error && (Object.keys(error).includes('email') || Object.keys(error).includes('common'))}
                                        onChange={setEmail}/>
                            {error && (Object.keys(error).includes('email') || Object.keys(error).includes('common')) &&
                                <span
                                    className="invalid-feedback text-center">* {error['email'] || error['common']}</span>}
                        </CardWithText>
                    </ContainerCard>
                    <ContainerCard>
                        <CardWithText textContent={t("auth:form.password") + "*"} measurementUnit={'%'} width="100"
                                      height="0"
                                      style={{margin: 0, padding: '34px'}}
                                      className="d-flex justify-content-center flex-column h-auto cardInputs m-0">
                            <InputRound className="mx-auto" placeholder={t('auth:form.password')} type="password"
                                        showErrors={error && (Object.keys(error).includes('password') || Object.keys(error).includes('common'))}
                                        onChange={setPassword}/>
                            {error && (Object.keys(error).includes('password') || Object.keys(error).includes('common')) &&
                                <span
                                    className="invalid-feedback text-center">* {error['password'] || error['common']}</span>}
                            <InputRound className="mx-auto" placeholder={t('auth:form.passwordConfirmation')}
                                        showErrors={error && (Object.keys(error).includes('passwordConfirmation') || Object.keys(error).includes('common'))}
                                        onChange={setPasswordConfirmation} type="password" style={{marginTop: '23px'}}/>
                            {error && (Object.keys(error).includes('passwordConfirmation') || Object.keys(error).includes('common')) &&
                                <span
                                    className="invalid-feedback text-center">* {error['passwordConfirmation'] || error['common']}</span>}
                        </CardWithText>
                        <CardWithText textContent={t('auth:register.fields.otherData')} measurementUnit={'%'}
                                      width="100" height="0"
                                      style={{margin: 0, padding: '34px'}}
                                      className="d-flex justify-content-center flex-column h-auto cardInputs m-0">
                            <div className="d-flex justify-content-center flex-wrap">
                                <div className="mt-2 mb-2 mt-sm-0 mb-sm-0">
                                    <SelectRound className="form-select" value={gender} onChange={setGender}>
                                        <option value="" disabled hidden></option>
                                        <option value="F">{t('common:female')}</option>
                                        <option value="M">{t('common:male')}</option>
                                    </SelectRound>
                                </div>
                                <div className="mt-2 mb-2 mt-sm-0 mb-sm-0">
                                    <DatepickerRound changeDate={setDateOfBirth}/>
                                </div>
                            </div>
                        </CardWithText>
                        <CardWithText textContent={t('auth:register.fields.referenceCode')} measurementUnit={'%'}
                                      width="100" height="0"
                                      style={{margin: 0, padding: '34px'}}
                                      className="d-flex justify-content-center flex-column h-auto cardInputs m-0">
                            <InputRound className="mx-auto"
                                        placeholder={t('auth:register.fields.referenceCodePlaceholder')}
                                        type="text"
                                        onChange={setCodeRefer}/>
                        </CardWithText>
                    </ContainerCard>
                </div>
                <div className="w-100 d-flex justify-content-sm-center">
                    <ButtonWhite type="submit" className="mx-auto" styleDefault={{marginTop: "45px"}}
                                 onClick={handleRegister}
                                 loading={isLoading}
                    >
                        {t('common:finish')}
                    </ButtonWhite>
                </div>
            </CardWithLogo>
        </ContainerRegister>
    </div>
}

export default FormRegister;