import React from "react";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import {useApp} from "../../context/app/AppContext";
import es from 'react-phone-number-input/locale/es'
import en from 'react-phone-number-input/locale/en'
import {useTranslation} from "react-i18next";

const BasePhoneNumber = styled.div`
  & .PhoneInput .PhoneInputCountry {
    height: 57px;
    padding-left: 15px;
    border: 1px solid ${props => props.invalid ? '#FA0606FF' : '#ced4da'};
    border-right: 0;
    border-radius: 30px 0 0 30px;
    margin-right: 0;
  }

  & .PhoneInput .PhoneInputInput {
    height: 57px;
    border: 1px solid ${props => props.invalid ? '#FA0606FF' : '#ced4da'};
    border-left: 0;
    border-radius: 0 30px 30px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: ${props => props.invalid ? '#FA0606FF' : '#212529'};
    padding-left: 5px;
  }

  & .PhoneInput .PhoneInputInput:focus-visible{
    outline: none;
  }

  & .PhoneInput .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: ${props => props.invalid ? '#FA0606FF' : '#212529'};
    border-top-width: 0;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 2px;
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: 1;
  }

  & .PhoneInput .PhoneInputCountryIcon--border {
    font-size: 20px;
    margin-right: 5px;
  }
`

const PhoneNumber = ({contactNumber, changeContactNumber, showErrors = false}) => {
    const locale = {
        'es': es,
        'en': en,
    }
    const {t} = useTranslation(['common'])
    const {language} = useApp();


    return <BasePhoneNumber invalid={showErrors}>
        <PhoneInput
            international
            placeholder={t('common:placeholderPhoneInput')}
            defaultCountry="CU"
            value={contactNumber}
            labels={locale[language]}
            onChange={changeContactNumber}/>
    </BasePhoneNumber>
}

export default PhoneNumber;