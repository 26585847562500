import React from "react";
import LogoPng from '../../assets/img/logo/img.png'

const Logo = ({widthValue, heightValue, style, ...props}) => {
    return <div className="logo" style={{width: widthValue, height: heightValue, ...style}} {...props}>
        <img src={LogoPng} alt="Logo" className="w-100 h-100"/>
    </div>
}

Logo.defaultProps = {
    widthValue: "82px",
    heightValue: "43px",
}

export default Logo;