const route = {
    'auth': {
        'index': '/auth',
        'login': 'login',
        'phone_number': 'phone-number',
        'password': 'password',
        'forgotten_password': {
            'index': 'forgotten-password',
            'validate_code': 'validate-code',
            'reset_password': 'reset-password',
        },
        'register': {
            'index': 'register',
            'validate_code': 'validate-code',
            'complete_your_registration': 'complete-your-registration'
        }
    },
    'customer': {
        'index': '/customer',
    }
}

export default route;