import React, {createContext, useContext, useState} from "react";
import AreaService from "../../services/app/AreaService";

const AreaContext = createContext();

const AreaContextProvider = props => {
    const [areaService] = useState(new AreaService())
    const [areas, setAreas] = useState([]);
    const instanceArea = {
        areas,
        getAreas: () => {
            areaService.getAreas().then(res => {
                setAreas(res)
            })
        }
    }
    return (
        <AreaContext.Provider value={instanceArea}>
            {props.children}
        </AreaContext.Provider>
    )

}

const useArea = () => {
    const context = useContext(AreaContext)
    if (context === undefined) {
        return {}
    }
    return context
}

export {
    AreaContextProvider,
    AreaContext,
    useArea
}