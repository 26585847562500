import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useApp} from "../../context/app/AppContext";
import Spanish from "../../assets/img/header/spanish.png"
import English from "../../assets/img/header/english.png"
import LogoYellow from "../logo/yellow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faLocationDot, faCartShopping} from "@fortawesome/free-solid-svg-icons";
import {faUser, faClock} from "@fortawesome/free-regular-svg-icons";
import {Dropdown} from "react-bootstrap";
import {useAuth} from "../../context/auth/AuthContext";

const HeaderContainer = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  width: 100%;
  height: 78px;
  color: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);

  .ml-27 {
    margin-left: 27px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-27 {
    margin-right: 27px;
  }

  .header-container {
    padding: 0 27px 0 27px;
  }

  @media (max-width: 600px) {
    .container-right {
      display: none!important;
    }
  }
`
const HeaderContainerLeft = styled.div`
  .options {
    border: 1px solid #E2E2E2;
    cursor: pointer;
    color: #000;
    width: 41px;
    height: 41px;
    border-radius: 50%;
  }
  .logo {
    width: 82px;
    height: 43px;
  }
  
  @media (max-width: 450px) {
    .logo {
      width: 62px;
      height: 33px;
    }
  }

  @media (max-width: 430px) {
    .logo {
      width: 42px;
      height: 23px;
    }
  }

  @media (max-width: 410px) {
    .logo {
      width: 22px;
      height: 13px;
    }
  }

  @media (max-width: 320px) {
    .options {
      height: 35px;
      width: 35px;
    }
  }
`

const HeaderContainerCenter = styled.div`
  .search {
    height: 50px;
    width: 1000px;
    border-radius: 30px;
    padding-left: 48px;
    border: 1px solid #E2E2E2;
  }

  .location-and-time {
    box-sizing: border-box;
    width: 245px;
    height: 50px;
    /* Gris Claro */
    border: 1px solid #E2E2E2;
    border-radius: 30px;
  }

  @media (max-width: 1620px) {
    .search {
      width: 820px;
    }
  }

  @media (max-width: 1420px) {
    .search {
      width: 720px;
    }
  }
  
  @media (max-width: 1380px) {
    .search {
      width: 636px;
    }
  }

  @media (max-width: 1280px) {
    .search {
      width: 536px;
    }
  }

  @media (max-width: 1140px) {
    .search {
      width: 436px;
    }
  }

  @media (max-width: 1040px) {
    .search {
      width: 336px;
    }
  }
  @media (max-width: 940px) {
    .search {
      width: 236px;
      padding-left: 28px;
    }
  }
  @media (max-width: 840px) {
    .search {
      width: 436px;
    }
    .location-and-time {
      display: none!important;
    }
  }
  @media (max-width: 780px) {
    .search {
      width: 336px;
    }
  }
  @media (max-width: 680px) {
    .search {
      width: 236px;
      padding-left: 14px;
    }
  }

  @media (max-width: 600px) {
    .search {
      width: 300px;
    }
  }

  @media (max-width: 510px) {
    .search {
      width: 270px;
    }
  }

  @media (max-width: 480px) {
    .search {
      width: 220px;
    }
  }
  @media (max-width: 370px) {
    .search {
      width: 170px;
    }
  }

  @media (max-width: 350px) {
    .search {
      height: 45px;
    }
  }

  @media (max-width: 320px) {
    .search {
      width: 150px;
      height: 35px;
      padding-left: 10px;
    }
  }
`

const HeaderContainerRight = styled.div`
  .options {
    //border: 1px solid #E2E2E2;
    cursor: pointer;
    color: #000;
    width: 41px;
    height: 41px;
    border-radius: 50%;
  }
  
  .btn-primary {
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disable-hover-bg: transparent;
    --bs-btn-disable-hover-border-color: transparent;
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 'auto'
  }


  @media (max-width: 600px) {
    & > div {
      flex-direction: column;
    }

    & > div > div {
      align-self: center;
      margin-top: 10px;
    }
  }
`

const Bar = styled.hr`
  height: 24px;
  width: 1px;
  border-width: 0;
  color: #000;
  background-color: #000;
`

const HeaderMenu = () => {
    const {t} = useTranslation(['footer', 'common', 'header'])
    const {language, changeLanguage} = useApp()
    const {logout} = useAuth()

    const handleChangeLanguage = (params) => {
        changeLanguage(params)
    }

    const handleLogout = () => {
        logout()
    };

    return <>
        <HeaderContainer>
            <div className="w-100 h-auto d-flex justify-content-between header-container align-items-center">
                <HeaderContainerLeft>
                    <div className="d-flex justify-content-evenly">
                        <div className="d-flex justify-content-evenly align-items-center">
                            <div className="d-flex options justify-content-evenly align-items-center me-2">
                                <FontAwesomeIcon icon={faBars} style={{color: "black"}}/>
                            </div>
                            <div className="d-flex justify-content-evenly align-items-center ms-2">
                                <LogoYellow/>
                            </div>
                        </div>
                    </div>
                </HeaderContainerLeft>
                <HeaderContainerCenter>
                    <div className="d-flex justify-content-between" style={{padding: '0 20px 0 20px'}}>
                        <div className="d-flex justify-content-center align-items-center me-1">
                            <input className='search' placeholder={t('header:search')}/>
                        </div>
                        <div className="d-flex justify-content-evenly align-items-center location-and-time ms-1">
                            <div className="d-flex" style={{cursor: "pointer"}}>
                                <div className="d-flex justify-content-evenly align-items-center ml-10 mr-10">
                                    <FontAwesomeIcon icon={faLocationDot} style={{color: "black"}}/>
                                </div>
                                <div className='ml-10'>
                                    <label style={{cursor: "pointer", color: "black"}}>Santa Marta</label>
                                </div>
                            </div>
                            <Bar/>
                            <div className="d-flex" style={{cursor: "pointer"}}>
                                <div className="d-flex justify-content-evenly align-items-center ml-10 mr-10">
                                    <FontAwesomeIcon icon={faClock} style={{color: "black"}}/>
                                </div>
                                <div className='ml-10'>
                                    <label style={{cursor: "pointer", color: "black"}}>26 Sept 10:00 PM</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </HeaderContainerCenter>
                <HeaderContainerRight>
                    <div className="d-flex container-right">
                        <div className="d-flex justify-content-evenly align-items-center">
                            <div className="d-flex options justify-content-center align-items-center">
                                <Dropdown className="w-100 h-100 d-flex justify-content-center align-items-center" style={{borderRadius: "30px"}}>
                                    <Dropdown.Toggle >
                                        <img src={language === 'es' ? Spanish : English } alt="Language" style={{width: '29px', height: '29px'}}/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="es" onClick={() => handleChangeLanguage('es')}><img src={Spanish} alt="Spanish" style={{width: '29px', height: '29px'}}/></Dropdown.Item>
                                        <Dropdown.Item eventKey="en" onClick={() => handleChangeLanguage('en')}><img src={English} alt="English" style={{width: '29px', height: '29px'}}/></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="d-flex options justify-content-evenly align-items-center">
                                <Dropdown className="w-100 h-100 d-flex justify-content-center align-items-center" style={{borderRadius: "30px"}}>
                                    <Dropdown.Toggle >
                                        <FontAwesomeIcon icon={faUser} style={{color: "black", width: '20px', height: '20px'}}/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="logout" onClick={handleLogout}>{t('header:logout')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="d-flex options justify-content-evenly align-items-center">
                                <FontAwesomeIcon icon={faCartShopping}
                                                 style={{color: "black", width: '20px', height: '20px'}}/>
                            </div>
                        </div>
                    </div>
                </HeaderContainerRight>
            </div>
        </HeaderContainer>
    </>
}

export default HeaderMenu;