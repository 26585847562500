import routes from '../route';
import _map from "lodash/map";
import {useEffect, useState} from "react";

const getNameForDict = (dict, name) => {
    if (typeof dict[name] === "string")
        return [dict[name], {}]
    if (typeof dict[name] === "object") {
        if (Object.keys(dict[name]).includes('index')) {
            return [dict[name]['index'], dict[name]]
        }
        return ['', dict[name]]
    }
    return ['', {}]
}

export const useParseRoute = (nameRoute) => {
    const [routeRegister, setRouteRegister] = useState('');
    useEffect(() => {
        let dict = routes
        let parseRoute = ''
        _map(nameRoute.split('.'), function (item) {
            let t1;
            [t1, dict] = getNameForDict(dict, item)
            parseRoute = parseRoute + t1 + '/'
        })
        parseRoute = parseRoute.slice(0, -1);
        setRouteRegister(parseRoute)
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameRoute])
    return routeRegister
}