import React, {useMemo, useState} from "react";
import Card from "react-bootstrap/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {useAuth} from "../../context/auth/AuthContext";
import UNLIKE from "../../assets/img/homeBody/unlike.png";
import LIKE from "../../assets/img/homeBody/like.png";
import {useCustomer} from "../../context/customer/CustomerContext";
import './css/restaurantCollection.css'
import {useTranslation} from "react-i18next";

const RestaurantCollectionCard= ({widthValue, heightValue, restaurantCollection, borderRadius, background, style, imageRadius, imageHeight, border, fontStyle, fontWeight, fontSize, lineHeight, color, margin, fontWeightRating, marginRating, ...props}) => {
    const {user} = useAuth()
    const {t} = useTranslation(['customer'])
    const [restaurantCollectionObj,setRestaurantCollectionObj] = useState(restaurantCollection)
    const {setFavoriteStore} = useCustomer()
    const handleFavorite = () => {
        const isFavorite = !restaurantCollectionObj.is_favorite
        setRestaurantCollectionObj(Object.assign({},restaurantCollectionObj,{is_favorite: isFavorite}))
        setFavoriteStore(restaurantCollectionObj.id, isFavorite)
    }
    return (
        <Card className="w-100 h-100" style={{borderRadius: borderRadius, border: border}}>
            <div className="d-flex" style={{height: imageHeight}}>
                <Card.Img src={restaurantCollectionObj.image? restaurantCollectionObj.image : restaurantCollectionObj.store_banner} alt="Card image" style={{borderRadius: imageRadius, height: imageHeight}}/>
                <Card.ImgOverlay style={{marginLeft: '25px', marginRight: '25px'}} className='d-flex align-items-start justify-content-end'>
                    <div className='d-flex flex-column h-50 justify-content-between'>
                        <div className='d-flex justify-content-center' onClick={() => handleFavorite()}>
                            { useMemo(() => {
                                return <img src={restaurantCollectionObj.is_favorite ? LIKE : UNLIKE } alt="Hart" style={{width: '35px', height: '35px'}}/>
                            }, [restaurantCollectionObj]
                            )}
                        </div>
                        {restaurantCollectionObj.offer_type !== 0 &&
                            <div className='d-flex justify-content-center'>
                            <span className='d-flex  align-items-center justify-content-center'
                                  style={{width: '53px', height: '35px', borderRadius: '23px', background: '#FF6501', fontWeight: '700', fontSize: '16px', lineHeight: '21px'}}>
                                -{restaurantCollectionObj.offer}{restaurantCollectionObj.offer_type === 2 ? '%' : user.currency}
                            </span>
                            </div>
                        }
                    </div>
                </Card.ImgOverlay>
            </div>
            <div className="d-flex h-100 w-100">
                <div className='d-flex flex-column w-100 h-100'>
                    <div className="d-flex">
                        <Card.Title style={{fontStyle: fontStyle, fontWeight: fontWeight, fontSize:fontSize, lineHeight: lineHeight, color: color, margin: margin}}>
                            {restaurantCollectionObj.name ? restaurantCollectionObj.name : restaurantCollectionObj.store_name}
                        </Card.Title>
                    </div>
                    <div className="d-flex justify-content-between" style={{fontStyle: fontStyle, fontWeight: fontWeightRating, fontSize:fontSize, lineHeight: lineHeight, color: color, margin: marginRating}}>
                        <div className='d-flex'>
                            <label>
                                {restaurantCollectionObj.rating ? restaurantCollectionObj.rating : restaurantCollectionObj.average_ratings}
                            </label>
                            <FontAwesomeIcon icon={faStar} style={{width: '19px', height: '20px', marginLeft: '5px', color: '#FFBF00'}}/>
                        </div>
                        {(restaurantCollectionObj.status === 0 || restaurantCollectionObj.store_status === 0) && <label className="me-2 is-close">{t('customer:close')}</label>}
                    </div>
                </div>
            </div>
        </Card>
    );
}

RestaurantCollectionCard.defaultProps = {
    widthValue: '246px',
    heightValue: '210px',
    border: 'border: 1px solid #E2E2E2',
    filter: 'drop-shadow(-5px 11px 10px rgba(0, 0, 0, 0.15))',
    borderRadius: '30px',
    imageRadius: '30px 30px 0px 0px',
    imageHeight: '129px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontWeightRating: '300',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
    margin: '13px 17px 5px 17px',
    marginRating: '0 17px 0 17px'
}

export default RestaurantCollectionCard;