import React, {useEffect, useState} from "react";
import {useAuth} from "../../../context/auth/AuthContext";
import {useTranslation} from "react-i18next";
import OtpInput from "react18-input-otp";
import {isNull} from "lodash";
import LogoM from "../../../assets/img/logo/logo_m.ico";
import CardWithLogo from "../../../components/cardWithLogo";
import {useNavigate} from "react-router-dom";
import ButtonWhite from "../../../components/buttonWhite";
import {useParseRoute} from "../../../hooks/parseRoute";

const FormValidateCode = () => {
    const [otp, setOtp] = useState('')
    const [isLoading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const {t} = useTranslation(['auth', 'common'])
    const [countdown, setCountdown] = useState(parseInt(process.env.REACT_APP_COUNTDOWN_RESEND_CODE_FORGOT_PASSWORD) || 30)
    const {
        error,
        setError,
        otpPhoneNumber,
        validateOtpPhoneNumber,
        setOtpForgotPassword,
        contactNumberRegister
    } = useAuth()

    const routeCompleteYourRegistration = useParseRoute('auth.register.complete_your_registration')
    const navigate = useNavigate();

    useEffect(() => {
        if (contactNumberRegister.length === 0) {
            setError(null)
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const timer =
            countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
        return () => clearInterval(timer);
    }, [countdown]);

    useEffect(() => {
        setDisabled(otp.length !== 4)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp])

    const handleChangeOtp = (value) => {
        setOtp(value)
        setError(null)
    }

    const handleResetCountdown = () => {
        setCountdown(parseInt(process.env.REACT_APP_COUNTDOWN_RESEND_CODE_FORGOT_PASSWORD) || 30)
    }

    const handleResendOtp = () => {
        if (countdown === 0) {
            handleResetCountdown()
            otpPhoneNumber(contactNumberRegister)
        }
    }

    const handleSendOtp = () => {
        setLoading(true)
        setDisabled(true)
        validateOtpPhoneNumber(parseInt(otp)).then(() => {
            setOtpForgotPassword(parseInt(otp))
            setOtp('')
            setLoading(false)
            navigate(routeCompleteYourRegistration)
        }).catch(() => {
            setOtp('')
            setLoading(false)
        })
    }

    return <div className="container d-flex justify-content-center">
        <div className="d-flex flex-column" style={{marginTop: "200px", lineHeight: "21px", fontSize: "18px"}}>
            <CardWithLogo logo={LogoM} className="d-flex flex-column" width={462} height={478}>
                <div className="w-75 text-center  mx-auto fs-2"
                     style={{fontWeight: 700, width: '400px', lineHeight: "38px"}}>
                    {t('auth:register.headerValidCode')}
                </div>
                <div className="w-auto text-center mt-3" style={{fontWeight: 400}}>
                    {t('auth:register.sentTo') + ' ' + contactNumberRegister}
                </div>
                <div className="w-auto mx-auto mt-4">
                    <OtpInput value={otp} onChange={handleChangeOtp} numInputs={4} separator={<span> - </span>}
                              isInputNum={true}
                              inputStyle={isNull(error) ? "form-control input-otp-verification mb-2" : "form-control input-otp-verification mb-2 invalid"}/>
                </div>
                <div className="mt-3 text-center" style={{fontWeight: 300}}>
                    <span>{t('auth:register.doesNotReach')} &nbsp;</span>
                    <span style={{fontWeight: 700}}
                          className={countdown === 0 ? "text-primary cursor-pointer" : "text-secondary cursor-pointer"}
                          onClick={handleResendOtp}>{t('auth:register.resendCode')} {countdown > 0 && '(' + countdown + ')'} </span>
                </div>
                <div className="w-auto mx-auto mt-5">
                    <ButtonWhite type="submit" className="mx-auto" styleDefault={{marginTop: "16px"}}
                                 onClick={handleSendOtp} disabled={disabled} loading={isLoading}>
                        {t('common:continue')}
                    </ButtonWhite>
                </div>
            </CardWithLogo>
        </div>
    </div>
}

export default FormValidateCode;