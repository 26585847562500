import React, {useEffect, useState} from "react";
import {useAuth} from "../../../context/auth/AuthContext";
import {useTranslation} from "react-i18next";
import {useParseRoute} from "../../../hooks/parseRoute";
import {useNavigate} from "react-router-dom";
import LogoM from "../../../assets/img/logo/logo_m.ico";
import CardWithLogo from "../../../components/cardWithLogo";
import InputRound from "../../../components/inputRound";
import {isNull} from "lodash";
import ButtonWhite from "../../../components/buttonWhite";
import {toast} from "react-toastify";

const FormResetPassword = () => {
    const {t} = useTranslation(['auth', 'common'])
    const {setError, error, resetPassword, emailForgotPassword, otpForgotPassword} = useAuth()
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const routeHome = useParseRoute('customer')

    useEffect(() => {
        if (isNull(otpForgotPassword) || emailForgotPassword.length === 0) {
            navigate('/')
            setError(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleResetPassword = () => {
        setLoading(true)
        setError(null)

        const data = Object.assign({}, {}, {
            password,
            "password_confirmation": passwordConfirmation
        })
        resetPassword(data).then(() => {
            setLoading(false)
            navigate(routeHome)
        }).catch(err => {
            if (err['message_code'] === 89) {
                toast.error(t('auth:forResetPassword.codeExpired'));
                navigate(routeHome)
            }
            setLoading(false)
        })
    };

    return <div className="container d-flex justify-content-center">
        <div className="d-flex flex-column" style={{marginTop: "200px"}}>
            <CardWithLogo logo={LogoM} className="d-flex flex-column" width={400} height={471}>
                <div className="w-75 text-center  mx-auto fs-2"
                     style={{fontWeight: 700, width: '400px', lineHeight: "38px"}}>
                    {t('auth:resetPassword')}
                </div>
                <div className="w-auto mx-auto mt-5">
                    <InputRound name="password"
                                type="password"
                                placeholder={t('auth:form.password')}
                                onChange={setPassword}
                                showErrors={!isNull(error)}/>
                </div>
                <div className="w-auto mx-auto mt-5">
                    <InputRound name="password_confirmation"
                                type="password"
                                placeholder={t('auth:form.passwordConfirmation')}
                                onChange={setPasswordConfirmation}
                                showErrors={!isNull(error)}/>
                </div>
                <div className="w-auto mx-auto mt-3">
                    {error && <span className="text-danger">{error}</span>}
                </div>
                <div className="w-auto mx-auto mt-5">
                    <ButtonWhite type="submit" className="mx-auto" styleDefault={{marginTop: "16px"}}
                                 onClick={handleResetPassword}
                                 loading={isLoading}>
                        {t('common:accept')}
                    </ButtonWhite>
                </div>
            </CardWithLogo>
        </div>
    </div>
}

export default FormResetPassword;