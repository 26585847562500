import React, {createContext, useContext, useState} from "react";
import AppService from "../../services/app/AppService";
import i18n from "../../i18n/i18n";

const AppContext = createContext();

const AppContextProvider = props => {
    const [appService] = useState(new AppService())
    const [pagesSettings, setPagesSettings] = useState(null)
    const [helpSettings, setHelpSettings] = useState(null)
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');
    const keyParseHelp = {
        'termConditions': 'Terms and Conditions',
        'contactPhone': 'Contact Phone',
        'google': 'Google',
        'facebook': 'Facebook'
    }


    const instanceApp = {
        language,
        pagesSettings,
        helpSettings,
        keyParseHelp,
        changeLanguage: async (param) => {
            await i18n.changeLanguage(param)
            setLanguage(param)
        },
        getPagesSettings: () => {
            appService.pagesSettings().then(res => setPagesSettings(res))
        },
        getHelpSettings: () => {
            appService.helpSettings({'only_settings': true}).then(res => setHelpSettings(res))
        }
    }

    return (
        <AppContext.Provider value={instanceApp}>
            {props.children}
        </AppContext.Provider>
    )
}

const useApp = () => {
    const context = useContext(AppContext)
    if (context === undefined) {
        return {}
    }
    return context
};

export {
    AppContextProvider,
    AppContext,
    useApp
}