import React, {useEffect, useState} from "react";
import LogoM from "../../../assets/img/logo/logo_m.ico";
import CardWithLogo from "../../../components/cardWithLogo";
import PhoneNumber from "../../../components/phoneNumber";
import ButtonWhite from "../../../components/buttonWhite";
import {isPossiblePhoneNumber, isValidPhoneNumber} from "react-phone-number-input";
import {useNavigate} from "react-router-dom";
import {useParseRoute} from "../../../hooks/parseRoute";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../../context/auth/AuthContext";


const PhoneNumberAuth = ({isLogin = true}) => {
    const [errorNumber, setErrorNumber] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const {t} = useTranslation(['auth', 'common'])
    const routePassword = useParseRoute('auth.password')
    const routeValidateCode = useParseRoute('auth.register.validate_code')
    const navigate = useNavigate();
    const {contactNumber, setContactNumber, setContactNumberRegister, otpPhoneNumber, error, setError} = useAuth()

    useEffect(() => {
        if (contactNumber !== undefined && contactNumber.length > 8) {
            const valid = isPossiblePhoneNumber(contactNumber) && isValidPhoneNumber(contactNumber);
            setDisabled(!valid)
            setErrorNumber(!valid)
        }
        if (contactNumber === undefined) {
            setErrorNumber(false)
        }
    }, [contactNumber])

    const handlerNextPage = () => {
        if (isPossiblePhoneNumber(contactNumber) && isValidPhoneNumber(contactNumber)) {
            handleNavigate()
        } else {
            setErrorNumber(true)
        }
    }

    const handleNavigate = () => {
        if (isLogin) {
            navigate(routePassword)
            return
        }
        setDisabled(true)
        setErrorNumber(false)
        setError(null)
        setContactNumberRegister(contactNumber)
        otpPhoneNumber(contactNumber).then(() => {
            navigate(routeValidateCode)
        }).catch(() => {
            setDisabled(false)
            setErrorNumber(true)
        })
    }

    return <div className="container d-flex justify-content-center">
        <div className="d-flex flex-column" style={{marginTop: "200px"}}>
            <CardWithLogo logo={LogoM} className="d-flex flex-column" width={462} height={399}>
                <div className="w-75 text-center  mx-auto fs-2"
                     style={{fontWeight: 700, width: '400px', lineHeight: "38px"}}>
                    {t('auth:headerPhoneNumber')}
                </div>
                <div className="w-auto mx-auto mt-3">
                    {!isLogin && <span className="">Te enviaremos un codigo de verificacion</span>}
                </div>
                <div className="w-auto mx-auto mt-2">
                    <PhoneNumber contactNumber={contactNumber} changeContactNumber={setContactNumber}
                                 showErrors={errorNumber}/>
                </div>
                <div className="w-auto mx-auto mt-2">
                    {error && <span className="text-danger">{error}</span>}
                </div>
                <div className="w-auto mx-auto mt-4">
                    <ButtonWhite className="mx-auto" disabled={disabled} styleDefault={{marginTop: "16px"}}
                                 onClick={handlerNextPage}>
                        {t('common:continue')}
                    </ButtonWhite>
                </div>
            </CardWithLogo>
        </div>
    </div>
}

export default PhoneNumberAuth;