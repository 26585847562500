import React from "react";
import {Button, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const ButtonYellow = ({styleDefault, styleButton, loading, ...props}) => {
    const {t} = useTranslation(['common'])

    return <Button variant="warning" disabled={loading} style={{...styleDefault, ...styleButton}} {...props}>
        {
            loading ? <>
                <Spinner className="mx-1"
                         as="span"
                         animation="border"
                         size="sm"
                         role="status"
                         aria-hidden="true"
                />
                {t('common:loading')}...
            </> : props.children
        }
    </Button>
}

ButtonYellow.defaultProps = {
    styleDefault: {},
    styleButton: {
        width: "223px",
        height: "47px",
        background: "#FFBF00",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        borderRadius: "40.5px"
    }
}

export default ButtonYellow;