import React, {useState} from "react";
import {useAuth} from "../../../context/auth/AuthContext";
import {useTranslation} from "react-i18next";
import LogoM from "../../../assets/img/logo/logo_m.ico";
import CardWithLogo from "../../../components/cardWithLogo";
import ButtonWhite from "../../../components/buttonWhite";
import {isNull} from "lodash";
import InputRound from "../../../components/inputRound";
import {useNavigate} from "react-router-dom";
import {useParseRoute} from "../../../hooks/parseRoute";

const ForgottenPassword = () => {
    const routeValidateCode = useParseRoute('auth.forgotten_password.validate_code')
    const navigate = useNavigate();
    const {t} = useTranslation(['auth', 'common'])
    const {error, forgotPassword} = useAuth()
    const [email, setEmail] = useState('')
    const [isLoading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true)

    const handleForgotPassword = () => {
        setLoading(true)
        forgotPassword({
            email
        }).then(() => {
            setLoading(false)
            navigate(routeValidateCode)
        }).catch(() => {
            setLoading(false)
        })
    };

    const handleChangeEmail = (value) => {
        setEmail(value)
        const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}/
        setDisabled(!re.test(value))
    }

    return <div className="container d-flex justify-content-center">
        <div className="d-flex flex-column" style={{marginTop: "200px"}}>
            <CardWithLogo logo={LogoM} className="d-flex flex-column" width={462} height={371}>
                <div className="w-75 text-center  mx-auto fs-2"
                     style={{fontWeight: 700, width: '400px', lineHeight: "38px"}}>
                    {t('auth:forgotPassword')}
                </div>
                <div className="w-auto mx-auto mt-3">
                    {error && <span className="text-danger">{error}</span>}
                </div>
                <div className="w-auto mx-auto mt-2">
                    <InputRound name="email"
                                type="email"
                                placeholder={t('auth:formForgotPassword.email')}
                                onChange={handleChangeEmail}
                                showErrors={!isNull(error)}/>
                </div>
                <div className="w-auto mx-auto mt-5">
                    <ButtonWhite type="submit" className="mx-auto" styleDefault={{marginTop: "16px"}}
                                 disabled={disabled}
                                 onClick={handleForgotPassword}
                                 loading={isLoading}>
                        {t('common:accept')}
                    </ButtonWhite>
                </div>
            </CardWithLogo>
        </div>
    </div>
}

export default ForgottenPassword;