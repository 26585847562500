import React, {createContext, useContext, useState} from "react";
import BannerService from "../../services/app/BannerService";
import RestaurantCategoryService from "../../services/app/RestaurantCategoryService";
import RestaurantCollectionService from "../../services/app/RestaurantCollectionService";
import StoreDetailService from "../../services/app/StoreDetailsService";
import {toInteger} from "lodash";
import ParametersFilterService from "../../services/app/ParametersFilterService";

const CustomerContext = createContext();

const CustomerContextProvider = props => {
    const [bannerService] = useState(new BannerService())
    const [restaurantCategoryService] = useState(new RestaurantCategoryService())
    const [productService] = useState(new RestaurantCollectionService())
    const [banners, setBanners] = useState([]);
    const [restaurantCategories, setRestaurantCategories] = useState([]);
    const [restaurantCollections, setRestaurantCollections] = useState([]);
    const [storeDetail] = useState(new StoreDetailService());
    const [storeList, setStoreList] = useState([]);
    const [parametersFilterService] = useState(new ParametersFilterService())
    const [parametersFilter, setParametersFilter] = useState([]);
    const instanceCustomer = {
        banners,
        storeDetail,
        storeList,
        restaurantCategories,
        restaurantCollections,
        parametersFilter,
        getBanner: () => {
            let params = {
                lat_long: '23.063244287943,-82.288705073297'
            }
            return bannerService.getBanner('home', params).then(res => {
                setBanners(res)
            })
        },
        getRestaurantCategories: () => {
            let params = {
                lat_long: '23.063244287943,-82.288705073297'
            }
            return restaurantCategoryService.getRestaurantCategories(params).then(res => {
                setRestaurantCategories(res)
            })
        },
        getRestaurantCollection: () => {
            let params = {
                lat_long: '23.063244287943,-82.288705073297'
            }
            return productService.getRestaurantCollection(params).then(res => {
                setRestaurantCollections(res)
            })
        },
        setFavoriteStore: (store_id, is_favourite) => {
            let params = {
                service_category_id: 5,
                store_id: store_id,
                is_favourite: toInteger(is_favourite)
            }
            return storeDetail.addFavoriteStore(params)
        },
        getStoreList: (filter_key_with_values) => {
            setStoreList([])
            let params = {
                service_category_id: 5,
                lat_long: '23.063244287943,-82.288705073297',
                filter_type: 'all',
                filter_key_with_values: filter_key_with_values
            }
            return storeDetail.getStoreList(params).then(res => {
                setStoreList(res)
            })
        },
        getParametersFilter: () => {
            let params = {
                lat_long: '23.063244287943,-82.288705073297'
            }
            return parametersFilterService.getParametersFilter(params).then(res => {
                setParametersFilter(res)
            })
        },
    }
    return (
        <CustomerContext.Provider value={instanceCustomer}>
            {props.children}
        </CustomerContext.Provider>
    )

}

const useCustomer = () => {
    const context = useContext(CustomerContext)
    if (context === undefined) {
        return {}
    }
    return context
}

export {
    CustomerContextProvider,
    CustomerContext,
    useCustomer
}