import React from "react";
import Card from "react-bootstrap/Card";
import Placeholder from 'react-bootstrap/Placeholder';
import CategoryImg  from "../../assets/img/homeBody/category.png";

const RestaurantCategoryCard= ({widthValue, heightValue, img, title, borderRadius, background, style, ...props}) => {
    return (
        <Card style={{borderRadius: borderRadius, borderColor: "white"}}>
            <Card.Img src={img?img:CategoryImg} alt="Card image" style={{borderRadius: borderRadius}}/>
            <Card.ImgOverlay>
                <Card.Title>{title}</Card.Title>
                {
                    !title && <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                }
            </Card.ImgOverlay>
        </Card>
    );
}

RestaurantCategoryCard.defaultProps = {
    widthValue: '128.97px',
    heightValue: '128.97px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 55.52%, rgba(0, 0, 0, 0.7) 99.1%)',
    filter: 'drop-shadow(-5px 11px 10px rgba(0, 0, 0, 0.15))',
    borderRadius: '30px',
}

export default RestaurantCategoryCard;