import React from "react";
import styled from "styled-components";

const BaseCard = styled.div`
  background: #FFFFFF;
  mix-blend-mode: normal;
  border: 1px solid #E2E2E2;
  border-radius: 30px;
  width: ${props => props.width ? props.width.toString() + props.measurementUnit : '318px'};
  height: ${props => props.height ? props.height.toString() + props.measurementUnit : '289px'};
  position: relative;
  margin: 60px auto 0 auto;
  padding-top: 47px;

  &:after {
    ${props => props.logo && `background-image: url("` + props.logo + `")`};
    background-size: 60px 60px;
    position: absolute;
    z-index: 0;
    content: "";
    top: 0;
    left: 50%;
    right: 50%;
    height: 60px;
    width: 60px;
    transform: translate(-50%, -50%);
  }
`

const CardWithLogo = ({measurementUnit = 'px', ...props}) => {
    return <BaseCard measurementUnit={measurementUnit} {...props}>
        {props.children}
    </BaseCard>
}

export default CardWithLogo;