import React, {forwardRef, useEffect, useState} from "react";
import styled from "styled-components";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import es from 'date-fns/locale/es';
import enUS from 'date-fns/locale/en-US';
import {useApp} from "../../context/app/AppContext";

registerLocale('es', es)
registerLocale('enUS', enUS)

const BaseSelectRound = styled.div`
  border: 1px solid ${props => props.invalid ? '#FA0606FF' : '#E2E2E2'};
  border-radius: 30px;
  width: 177px;
  height: 57px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  position: relative;

  & > .iconCalendar {
    position: absolute;
    top: 18px;
    right: 16px;
  }
`;


const DatepickerRound = ({changeDate, maxDate = new Date(), dateFormat = "yyyy/MM/dd", ...props}) => {
    const [startDate, setStartDate] = useState('');
    const {language} = useApp()

    useEffect(() => {
        changeDate(moment(startDate).format(dateFormat.toUpperCase()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate])

    const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
        <BaseSelectRound onClick={onClick} ref={ref} className="d-flex justify-content-center" {...props}>
            <span className="h-auto my-auto">{value}</span>
            <FontAwesomeIcon className="iconCalendar" icon={faCalendar}/>
        </BaseSelectRound>));
    return <DatePicker
        selected={startDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        onChange={(date) => setStartDate(date)}
        customInput={<ExampleCustomInput/>}
        fixedHeight
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale={language === 'en' ? "enUS" : "es"}
    />
}

export default DatepickerRound;