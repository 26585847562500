import React from "react";
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from "../../context/auth/AuthContext";

const Layout = () => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/" />;
    }

    return <>
        <Outlet/>
    </>
}

export default Layout;