import React from "react";
import styled from "styled-components";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";


const LabelNews = styled.label`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  align-self: center;
  text-transform: uppercase;
`
const LabelNewsDescription = styled.label`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  align-self: center;
`


const NewsLetter = () => {
    const {t} = useTranslation(['common', 'home'])

    return <div className="w-100 h-100 background-mandao-color d-flex justify-content-center align-items-center"
                style={{minHeight: "259px"}}>
        <div className="w-auto d-flex flex-column">
            <LabelNews>Newsletter</LabelNews>
            <LabelNewsDescription>{t('home:newDescription')}</LabelNewsDescription>
            <Button variant="outline-dark" className="w-100 align-self-center"
                    style={{
                        borderRadius: "40.5px",
                        maxWidth: "223px",
                        height: "47px",
                        marginTop: "30px"
                    }}>{t('common:toSubscribe')}</Button>
        </div>
    </div>
}

export default NewsLetter;