import React, {useEffect, useState} from "react";
import styled from "styled-components";

const BaseCard = styled.div`
  background: #FFFFFF;
  mix-blend-mode: normal;
  border: 1px solid #E2E2E2;
  border-radius: 30px;
  width: ${props => props.width ? props.width.toString() + props.measurementUnit : '318px'};
  height: ${props => props.height ? props.height.toString() + props.measurementUnit : '289px'};
  position: relative;
  margin: 60px auto 0 auto;

  &:after {
    ${props => props.logo && `background-image: url("` + props.logo + `")`};
    background-size: 60px 60px;
    position: absolute;
    z-index: 0;
    content: "${props => props.textContent}";
    top: 0;
    left: 50%;
    right: 50%;
    height: auto;
    width: ${props => props.widthText}px;
    max-width: 100%;
    transform: translate(-50%, -55%);
    text-align: center;
    background-color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 34px 10px !important;
  }
`

const CardWithText = ({measurementUnit = 'px', textContent = '', ...props}) => {
    const [widthText, setWidthText] = useState(0)

    useEffect(() => {
        setWidthText((textContent.length + 4) * 8)
    }, [textContent])

    return <BaseCard measurementUnit={measurementUnit} widthText={widthText} textContent={textContent} {...props}>
        {props.children}
    </BaseCard>
}

export default CardWithText;