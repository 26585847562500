import React, {createContext, useContext, useState} from "react";
import AuthService from "../../services/app/AuthService";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import _map from "lodash/map";

const AuthContext = createContext()

const AuthContextProvider = props => {
    const {t} = useTranslation(['common', 'auth'])
    const [authService] = useState(new AuthService())
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
    const [emailForgotPassword, setEmailForgotPassword] = useState(null)
    const [contactNumberRegister, setContactNumberRegister] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [otpForgotPassword, setOtpForgotPassword] = useState(null)
    const [showModal, setShowModal] = useState(null);
    const [error, setError] = useState(null)

    const validateRegisterField = (data) => {
        const requiredField = ['first_name', 'last_name', 'email', 'password', 'passwordConfirmation']
        const dict = {}
        _map(Object.keys(data), function (item) {
            if (requiredField.includes(item) && data[item] === '') {
                dict[item] = 'This field is required'
            }
        })

        return dict
    }

    const instanceAuth = {
        user,
        error,
        setError,
        showModal,
        contactNumber,
        setContactNumber,
        emailForgotPassword,
        otpForgotPassword,
        setOtpForgotPassword,
        contactNumberRegister,
        setContactNumberRegister,
        setShowModal,
        login: (params) => {
            const data = Object.assign({}, params, {
                'login_type': 'email',
                'device_token': '24324324234',
                'select_currency': user?.select_currency || '$',
                'login_device': 3
            })
            setError(null)
            return authService.login(data).then(res => {
                setUser(res)
                setError(null)
            }).catch(err => {
                setError(t('auth:errorLogin'))
                throw err
            })
        },
        logout: () => {
            authService.logout().then(() => {
                window.location.href = '/'
            })
        },
        forgotPassword: (params) => {
            setError(null)
            return authService.forgotPassword(params).then(() => {
                setEmailForgotPassword(params['email'])
                setError(null)
            }).catch(() => {
                setEmailForgotPassword(null)
            })
        },
        validateOtpEmail: (otp) => {
            const params = Object.assign({}, {}, {
                'code': otp,
                'email': emailForgotPassword
            })
            setError(null)
            return authService.validateOTP(params).then(res => {
                return res
            }).catch(() => {
                setError('Faild.')
            })
        },
        resetPassword: (values) => {
            const params = Object.assign({}, {'code': otpForgotPassword}, {...values})
            setError(null)
            return authService.resetPassword(params).then(res => {
                setUser(res)
                localStorage.setItem('user', JSON.stringify(res))
            }).catch(err => {
                if (err['message_code'] === 89) {
                    toast.error(t('auth:forResetPassword.codeExpired'));
                    setError(null)
                    throw err
                }
                setError(t('auth:forResetPassword.errorPassword'))
                throw err
            })
        },
        otpPhoneNumber: (contact_number) => {
            const params = Object.assign({}, {}, {contact_number})
            setError(null)
            return authService.otpPhoneNumber(params).then(res => {
                return res
            }).catch(err => {
                setError('El número de telefono ya esta en uso');
                throw err
            })
        },
        validateOtpPhoneNumber: (otp) => {
            setError(null)
            const params = Object.assign({}, {}, {
                'code': otp,
                'contact_number': contactNumberRegister
            })
            return authService.validateOtpPhoneNumber(params).then(res => {
                return res
            }).catch(err => {
                setError('Codigo incorrecto.');
                throw err
            })
        },
        register: (params) => {
            return new Promise((resolve, reject) => {
                const data = Object.assign({}, params, {
                    contact_number: contactNumber
                })
                const valid = validateRegisterField(data)
                if (Object.keys(valid).length !== 0) {
                    setError(valid)
                    throw new Error();
                }
                authService.register(data).then(res => {
                    if (res.status === 0) {
                        reject(res)
                    }
                    setUser(res)
                    setError(null)
                    resolve(res)
                })
            }).catch(err => {
                if (err.message_code === 9) {
                    setError({'common': 'incorrect'})
                } else if (err.message_code === 11) {
                    setError({'email': 'unique'})
                }
                throw err
            });
        }

    }

    return (
        <AuthContext.Provider value={instanceAuth}>
            {props.children}
        </AuthContext.Provider>
    )
}

const useAuth = () => {
    const context = useContext(AuthContext)
    if (context === undefined) {
        return {}
    }
    return context
};

export {
    AuthContextProvider,
    AuthContext,
    useAuth
}