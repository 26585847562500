import React from "react";
import CirclesWithImgAndColor from "../../../components/circlesWithImgAndColor";
import MapCuba from "../../../assets/img/home/cuba.png";
import DollarSymbol from "../../../assets/img/home/dollar_symbol.png";
import Congratulations from "../../../assets/img/home/congratulations.png";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const FeaturesCardContainer =  styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 80px 0;
  
  @media (max-width: 760px){
    display: flex;
    flex-direction: column;
    padding: 40px 0;

    &>div{
      align-self: center;
    }
  }
  
`

const FeaturesCard = () => {
    const {t} = useTranslation(['home'])

    return <>
        <FeaturesCardContainer>
            <div className="d-flex flex-column p-3">
                <CirclesWithImgAndColor imgValue={MapCuba} styleCircle={{alignSelf: "center"}}
                                        styleImg={{width: "79.85px", height: "46px"}}/>
                <div  className="text-center" style={{marginTop: "27px", alignSelf: "center"}}>
                    <label className="text-center" style={{fontWeight: 400, fontSize: "24px", lineHeight: "28px"}}>
                        {t('cardMapCubaTitle')}
                    </label>
                    <p className="text-center" style={{fontWeight: 300, fontSize: "16px", lineHeight: "19px"}}>
                        {t('cardMapCubaDescription')}
                    </p>
                </div>
            </div>
            <div className="d-flex flex-column p-3">
                <CirclesWithImgAndColor imgValue={DollarSymbol} styleCircle={{alignSelf: "center"}}
                                        styleImg={{width: "42px", height: "51px"}}/>
                <div  className="text-center" style={{marginTop: "27px", alignSelf: "center"}}>
                    <label className="text-center" style={{fontWeight: 400, fontSize: "24px", lineHeight: "28px"}}>
                        {t('cardDollarSymbolTitle')}
                    </label>
                    <p className="text-center" style={{fontWeight: 300, fontSize: "16px", lineHeight: "19px"}}>
                        {t('cardDollarSymbolDescription')}
                    </p>
                </div>
            </div>
            <div className="d-flex flex-column p-3 ">
                <CirclesWithImgAndColor imgValue={Congratulations} styleCircle={{alignSelf: "center"}}
                                        styleImg={{width: "42px", height: "57px"}}/>
                <div  className="text-center" style={{marginTop: "27px", alignSelf: "center"}}>
                    <label className="text-center" style={{fontWeight: 400, fontSize: "24px", lineHeight: "28px"}}>
                        {t('cardCongratulationsTitle')}
                    </label>
                    <p className="text-center" style={{fontWeight: 300, fontSize: "16px", lineHeight: "19px"}}>
                        {t('cardCongratulationsDescription')}
                    </p>
                </div>
            </div>
        </FeaturesCardContainer>
    </>
}

export default FeaturesCard;