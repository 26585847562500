import {DefaultService} from "./DefaultService";

class Auth extends DefaultService {
    login(params) {
        return this.post('/customer/login', params).then(res => {
            if (res.status !== 1) {
                throw Error(res.message)
            }
            localStorage.setItem('user', JSON.stringify(res))
            return res
        })
    }

    logout(params={}) {
        return this.post('/customer/logout', params).then(() => {
            localStorage.clear()
        })

    }

    forgotPassword(params) {
        return this.post('/customer/forgot-password-request', params).then(res => {
            if (res.status === 0) {
                throw Error(res.message)
            }
            return res
        })
    }

    validateOTP(params) {
        return this.post('/customer/forgot-password-validate-code', params).then(res => {
            if (res.status === 0) {
                throw Error(res.message)
            }
            return res
        })
    }

    resetPassword(params) {
        return this.post('/customer/forgot-change-password', params).then(res => {
            if (res.status === 0) {
                throw Error(res.message)
            }
            return res
        })
    }

    otpPhoneNumber(params) {
        return this.post('/validation/otp-phone-number', params).then(res => {
            if (res.status === 0) {
                throw Error(res.message)
            }
            return res
        })
    }

    validateOtpPhoneNumber(params) {
        return this.post('/validation/code-otp-phone-number', params).then(res => {
            if (res.status === 0) {
                throw Error(res.message)
            }
            return res
        })
    }

    register(params){
        return this.postFormData('/customer/register', params).then(res => {
            if (res.status !== 1) {
                return res
            }
            localStorage.setItem('user', JSON.stringify(res))
            return res
        })
    }



}

export default Auth;