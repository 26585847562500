import React from "react";
import Banner from "./components/banner";
import FeaturesCard from "./components/featuresCard";
import AboutUs from "./components/aboutUs";
import Footer from "../../components/footer";
import NewsLetter from "../../components/newsLetter";

const Home = () => {
    return <div className="w-100">
        <Banner/>
        <FeaturesCard/>
        <AboutUs/>
        <NewsLetter/>
        <Footer/>
    </div>

}

export default Home;
