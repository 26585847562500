import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BannersCard from "../cards/bannersCard";
import _map from "lodash/map";
import './css/bannerCarrousel.css'
import _isEmpty from "lodash/isEmpty";

const BannersGallery = ({banners}) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1780},
            items: 4
        },
        desktop2: {
            breakpoint: {max: 1779, min: 1001},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1000, min: 0},
            items: 2
        }
    };
    return (
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container w-100"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="d-flex justify-content-center banner-carrousel"
        >
            {
                _map(_isEmpty(banners)?Array(3):banners, (value, index) => {
                    return <div className='d-flex banners' key={'banner-' + index}>
                        <BannersCard img={value?value.image:null} title={value?value.title:null}></BannersCard>
                    </div>
                })
            }
        </Carousel>
    )
}

export default BannersGallery;