import React, {useEffect, useState} from "react";
import {useAuth} from "../../../context/auth/AuthContext";
import {useTranslation} from "react-i18next";
import OtpInput from "react18-input-otp";
import {isNull} from "lodash";
import LogoM from "../../../assets/img/logo/logo_m.ico";
import CardWithLogo from "../../../components/cardWithLogo";
import {useParseRoute} from "../../../hooks/parseRoute";
import {useNavigate} from "react-router-dom";

const FormValidateCode = () => {
    const {t} = useTranslation(['auth', 'common'])
    const [otp, setOtp] = useState('')
    const [countdown, setCountdown] = useState(parseInt(process.env.REACT_APP_COUNTDOWN_RESEND_CODE_FORGOT_PASSWORD) || 30)
    const {
        error,
        setError,
        validateOtpEmail,
        setOtpForgotPassword,
        forgotPassword,
        emailForgotPassword
    } = useAuth()
    const routeResetPassword = useParseRoute('auth.forgotten_password.reset_password')
    const navigate = useNavigate();

    useEffect(() => {
        if (validateOtpEmail.length === 0) {
            navigate('/')
            setError(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeOtp = (value) => {
        setOtp(value)
        setError(null)
    }

    const handleResetCountdown = () => setCountdown(parseInt(process.env.REACT_APP_COUNTDOWN_RESEND_CODE_FORGOT_PASSWORD) || 30)

    const handleResendOtp = () => {
        handleResetCountdown()
        forgotPassword(Object.assign({}, {}, {'email': emailForgotPassword}))
    }

    useEffect(() => {
        if (otp.length === 4) {
            validateOtpEmail(parseInt(otp)).then(() => {
                setOtpForgotPassword(parseInt(otp))
                setOtp('')
                navigate(routeResetPassword)
            }).catch(() => {
                setOtp('')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp])

    React.useEffect(() => {
        const timer =
            countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
        return () => clearInterval(timer);
    }, [countdown]);


    return <div className="container d-flex justify-content-center">
        <div className="d-flex flex-column" style={{marginTop: "200px"}}>
            <CardWithLogo logo={LogoM} className="d-flex flex-column" width={362} height={371}>
                <div className="w-75 text-center  mx-auto fs-2"
                     style={{fontWeight: 700, width: '400px', lineHeight: "38px"}}>
                    {t('auth:forgotPassword')}
                </div>
                <div className="w-auto mx-auto mt-5">
                    <OtpInput value={otp} onChange={handleChangeOtp} numInputs={4} separator={<span> - </span>}
                              isInputNum={true}
                              inputStyle={isNull(error) ? "form-control input-otp-verification mb-2" : "form-control input-otp-verification mb-2 invalid"}/>
                </div>
                <div className="mt-3 text-center" style={{fontWeight: 300}}>
                    <span>{t('auth:register.doesNotReach')} &nbsp;</span>
                    <span style={{fontWeight: 700}}
                          className={countdown === 0 ? "text-primary cursor-pointer" : "text-secondary cursor-pointer"}
                          onClick={handleResendOtp}>{t('auth:register.resendCode')} {countdown > 0 && '(' + countdown + ')'} </span>
                </div>
            </CardWithLogo>
        </div>
    </div>
}

export default FormValidateCode;