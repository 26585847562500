import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CardWithLogo from "../../../components/cardWithLogo";
import Driver from "../../../assets/img/presentation_gif/driver.gif";
import LogoM from "../../../assets/img/logo/logo_m.ico";
import ButtonYellow from "../../../components/buttonYellow";
import ButtonWhite from "../../../components/buttonWhite";
import Google from "../../../assets/img/icons/google_color.png";
import Facebook from "../../../assets/img/icons/facebook_blue.png";
import {Button, Modal} from "react-bootstrap";
import {useApp} from "../../../context/app/AppContext";
import _map from "lodash/map";
import {useNavigate} from "react-router-dom";
import {useParseRoute} from "../../../hooks/parseRoute";
import {isNull} from "lodash";


const Login = () => {
    const {t} = useTranslation(['auth', 'common'])
    const [term, setTerm] = useState('');
    const {pagesSettings, getPagesSettings, language, helpSettings, getHelpSettings, keyParseHelp} = useApp();
    const [modalShow, setModalShow] = React.useState(false);
    const navigate = useNavigate();
    const routePhoneNumber = useParseRoute('auth.phone_number')

    useEffect(() => {
        if (isNull(pagesSettings)) {
            getPagesSettings()
        }
        if (isNull(helpSettings)) {
            getHelpSettings()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (pagesSettings !== null) {
            _map(pagesSettings, function (item) {
                if (item.en_name === keyParseHelp.termConditions) {
                    setTerm(language === 'es' ? item.es_description : item.en_description)
                }
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesSettings, language])


    return <div className="container d-flex justify-content-center">
        <div className="d-flex flex-column" style={{marginTop: "82px"}}>
            <img className="m-auto" src={Driver} alt="Driver" style={{width: "255px", height: "143px"}}/>
            <div className="mt-4 fs-2 text-center" style={{fontWeight: 700, width: '400px', lineHeight: "38px"}}>
                <p className="m-0">{t('auth:login.headerPage')} </p>
            </div>
            <CardWithLogo logo={LogoM} className="d-flex flex-column">
                {
                    helpSettings && helpSettings.social_network.some(el => el.name === keyParseHelp.contactPhone) &&
                    <ButtonYellow className="mx-auto" styleDefault={{marginTop: "16px"}}
                                  onClick={() => navigate(routePhoneNumber)}>
                        {t('common:phone')}
                    </ButtonYellow>
                }
                {
                    helpSettings && helpSettings.social_network.some(el => el.name === keyParseHelp.google) &&
                    <ButtonWhite className="mx-auto" styleDefault={{marginTop: "16px"}}>
                        <div className="w-100 d-flex justify-content-center" style={{fontWeight: 400}}>
                            <img src={Google} alt="Google" style={{width: "24px", height: "24px"}}/>
                            <label className="mx-2 d-grid align-content-center cursor-pointer">
                                Google
                            </label>
                        </div>
                    </ButtonWhite>
                }
                {
                    helpSettings && helpSettings.social_network.some(el => el.name === keyParseHelp.facebook) &&
                    <ButtonWhite className="mx-auto" styleDefault={{marginTop: "16px"}}>
                        <div className="w-100 d-flex justify-content-center" style={{fontWeight: 400}}>
                            <img src={Facebook} alt="Google" style={{width: "20px", height: "20px"}}/>
                            <label className="mx-2 d-grid align-content-center cursor-pointer text-primary">
                                Facebook
                            </label>
                        </div>
                    </ButtonWhite>
                }

            </CardWithLogo>
            <div className="mt-5 text-center mx-auto" style={{fontWeight: 400, width: '268px', lineHeight: "19px"}}>
                <label className="m-0">{t('auth:login.footerPage')} &nbsp;
                    <label onClick={() => setModalShow(true)}
                           className="m-0 text-decoration-underline text-primary cursor-pointer">{t('common:terms')}</label>
                </label>
            </div>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow}
               onHide={() => setModalShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('common:terms')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{__html: term}}></div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setModalShow(false)}>{t('common:close')}</Button>
            </Modal.Footer>
        </Modal>
    </div>
}

export default Login;