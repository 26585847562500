import React, {useState} from "react";
import UploadAvatar from "../../assets/img/register/upload_avatar.png"
import Plus from "../../assets/img/register/plus.png"
import styled from "styled-components";

const BaseUploadProfile = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 100%;
  position: relative;
  cursor: pointer;


  :after {
    ${props => props.plusImage && `background-image: url("` + props.plusImage + `")`};
    background-size: 30px 30px;
    position: absolute;
    z-index: 0;
    content: "";
    top: 45.18px;
    left: 52.18px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  & img {
    object-fit: cover;
    border-radius: 100%;
  }
`

const UploadProfile = ({onChange, onChangeNameFile, ...props}) => {
    const [src, setSrc] = useState(UploadAvatar);
    const photoUpload = e => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setSrc(reader.result)
            onChange && onChange(file)
            onChangeNameFile && onChangeNameFile(file.name)
        }
        reader.readAsDataURL(file);
    }

    return <div className="w-auto h-auto base-div" {...props}>
        <label htmlFor="photo-upload" className="custom-file-upload fas">
            <BaseUploadProfile plusImage={Plus}>
                <img src={src} alt="Profile image" className="w-100 h-100"/>
            </BaseUploadProfile>
            <input id="photo-upload" className="visually-hidden" type="file" onChange={photoUpload}/>
        </label>
    </div>
}

export default UploadProfile;