import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import HomeLogged from "./pages/homeLogged";
import Layout from "./pages/templates";
import NotFound from "./pages/errors/notFound";
import Login from "./pages/auth/login";
import PhoneNumberAuth from "./pages/auth/phoneNumberAuth";
import FormLogin from "./pages/auth/login/formLogin";
import ForgottenPassword from "./pages/auth/forgottenPassword";
import FormValidateCode from "./pages/auth/forgottenPassword/formValidateCode";
import FormResetPassword from "./pages/auth/forgottenPassword/formResetPassword";
import FormValidateCodeR from "./pages/auth/register/formValidateCode";
import FormRegister from "./pages/auth/register/formRegister";
import routes from './route';
import Unauthenticated from "./pages/templates/unauthenticated";

function App() {

    return (
        <Router>
            <Routes>
                <Route element={<Unauthenticated/>}>
                    <Route index element={<Home/>}/>
                    <Route path={routes.auth.index}>
                        <Route path={routes.auth.login} element={<Login/>}/>
                        <Route path={routes.auth.phone_number} element={<PhoneNumberAuth/>}/>
                        <Route path={routes.auth.password} element={<FormLogin/>}/>
                        <Route path={routes.auth.forgotten_password.index}>
                            <Route index element={<ForgottenPassword/>}/>
                            <Route path={routes.auth.forgotten_password.validate_code} element={<FormValidateCode/>}/>
                            <Route path={routes.auth.forgotten_password.reset_password} element={<FormResetPassword/>}/>
                        </Route>
                        <Route path={routes.auth.register.index}>
                            <Route index element={<PhoneNumberAuth isLogin={false}/>}/>
                            <Route path={routes.auth.register.validate_code} element={<FormValidateCodeR/>}/>
                            <Route path={routes.auth.register.complete_your_registration} element={<FormRegister/>}/>
                        </Route>
                    </Route>
                </Route>
                <Route path={routes.customer.index} element={<Layout/>}>
                    <Route index element={<HomeLogged/>}/>
                </Route>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
        </Router>
    );
}

export default App;
