import React, {useEffect} from "react";
import styled from 'styled-components';
import Logo from "../../../components/logo";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form"
import {Button} from "react-bootstrap";
import Bag from "../../../assets/img/banner/bag.png"
import FloatingCard from "../../../components/floatingCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {useArea} from "../../../context/area/AreaContext";
import _map from 'lodash/map';
import {useParseRoute} from "../../../hooks/parseRoute";
import {useNavigate} from "react-router-dom";

const ContainerBanner = styled.div`
  height: 756px;
  background: linear-gradient(180deg, rgba(255, 101, 1, 0) 0%, rgba(255, 101, 1, 0.4) 100%), #FFBF00;
  display: flex;
  position: relative;
  @media (max-width: 600px) {
    height: 100%;
  }
`;
const ContainerBannerLeft = styled.div`
  width: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;

  & div label {
    font-weight: 700;
    font-size: 64px;
    line-height: 100%;
    color: #000000;
  }

  & .logo {
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    .logo {
      align-self: center;
    }

    .container-label {
      margin-top: 100px !important;
      text-align: center;
    }

    .container-form {
      align-self: center;
      flex-direction: column;
    }

    .container-form div {
      margin-left: 0 !important;
      align-self: center;
    }
  }

  @media (min-width: 768px) {
    padding-left: 45px;
  }
  @media (min-width: 1024px) {
    width: 50%;
    padding-left: 67.5px;
    & div label {
      font-size: 64px;
    }

    .logo {
      margin-top: 103px;
    }

    .container-label, .container-form {
      align-self: flex-start !important;
    }
  }
  @media (min-width: 1280px) {
    padding-left: 135px;
  }
`
const ContainerBannerRight = styled.div`
  width: 50%;
  display: none;

  & img {
    margin-top: auto;
  }

  @media (min-width: 860px) {
    display: flex;
    justify-content: center;
    height: 100%;
    & img {
      width: 410px;
      height: 550px;
    }
  }
  @media (min-width: 1440px) {
    & img {
      width: 720px;
      height: 756px;
    }
  }
  @media (min-width: 1024px) {
    & img {
      width: 520px;
      height: 656px;
    }
  }
  @media (min-width: 1240px) {
    & img {
      width: 620px;
      height: 710px;
    }
  }
`
const Bar = styled.hr`
  height: 24px;
  width: 1px;
  border-width: 0;
  color: #000;
  background-color: #000;
`
const CustomerFloatingCard = styled(FloatingCard)`
  @media (max-width: 600px) {
    top: 115px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`

const Banner = () => {
    const {t} = useTranslation(['common', 'auth'])
    const {areas, getAreas} = useArea();
    const navigate = useNavigate();
    const routeLogin = useParseRoute('auth.login')
    const routeRegister = useParseRoute('auth.register')

    useEffect(() => {
        getAreas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        <ContainerBanner>
            <ContainerBannerLeft>
                <Logo widthValue="149px" heightValue="78px"/>
                <div className="align-self-center container-label"
                     style={{marginTop: "42px", maxWidth: "458px", minHeight: "220px"}}>
                    <label>
                        {t('common:homeBannerText')}
                    </label>
                </div>
                <div className="mt-3 w-100 d-flex flex-wrap align-self-center container-form">
                    <div className="mb-3" style={{width: "363px", height: "64px"}}>
                        <Form.Select className="w-100 h-100" style={{borderRadius: "30px"}}>
                            <option>{t('common:placeholderSelectArea')}</option>
                            {
                                _map(areas, item => {
                                    return <option value={item.id} key={item.id}>{item.name}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                    <div className="mb-3" style={{width: "158px", height: "64px", marginLeft: "17px"}}>
                        <Button variant="outline-dark" className="w-100 h-100"
                                style={{borderRadius: "40.5px"}}>{t('common:search')}</Button>
                    </div>
                </div>

            </ContainerBannerLeft>
            <ContainerBannerRight>
                <img src={Bag} alt="Bag" style={{}}/>
            </ContainerBannerRight>
            <CustomerFloatingCard widthValue="227px" heightValue="48px" rightValue="180px" topValue="29px">
                <div className="w-100 h-100 d-flex justify-content-evenly align-items-center">
                    <div className="d-flex" style={{cursor: "pointer"}} onClick={() => navigate(routeLogin)}>
                        <div>
                            <FontAwesomeIcon icon={faUser}/>
                        </div>
                        <div style={{marginLeft: "10px"}}>
                            <label style={{cursor: "pointer"}}>{t('auth:login.name')}</label>
                        </div>
                    </div>
                    <Bar/>
                    <div style={{cursor: "pointer"}} onClick={() => navigate(routeRegister)}>
                        <label style={{cursor: "pointer"}}>{t('auth:signUp')}</label>
                    </div>
                </div>
            </CustomerFloatingCard>
        </ContainerBanner>
    </>
}

export default Banner;
