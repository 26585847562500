import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import _map from "lodash/map";
import RestaurantCollectionCard from "../cards/restaurantCollectionCard";
import './css/restaurantCollectionCarrousel.css'
import _isEmpty from "lodash/isEmpty";
import RestaurantCollectionCardSkeleton from "../cards/restaurantCollectionCardSkeleton";

const RestaurantCollectionGallery = ({restaurantCollections}) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 2999, min: 1600 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1599, min: 1330 },
            items: 5
        },
        miniTablet: {
            breakpoint: { max: 1329, min: 1080 },
            items: 4
        },
        largeMobile: {
            breakpoint: { max: 1079, min: 820 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 819, min: 0 },
            items: 2
        },
        // miniMobile: {
        //     breakpoint: { max: 599, min: 0 },
        //     items: 1
        // }
    };
    return (
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={false}
            // autoPlay={this.props.deviceType !== "mobile"}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container w-100 h-100"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="d-flex justify-content-center"
        >
            {
                _map(_isEmpty(restaurantCollections)?Array(3):restaurantCollections, (value, index) => {
                    if (!value) {
                        return <div className='d-flex restaurantCollection' key={'restaurant-collection-' + index}>
                            <RestaurantCollectionCardSkeleton></RestaurantCollectionCardSkeleton>
                        </div>
                    }
                    return <div className='d-flex restaurantCollection' key={'restaurant-collection-' + index}>
                        <RestaurantCollectionCard restaurantCollection={value}></RestaurantCollectionCard>
                    </div>
                })
            }
        </Carousel>
    )
}

export default RestaurantCollectionGallery;