import React, {useEffect, useRef, useState} from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import './css/moneyRangeBannerStyle.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleChevronDown, faCircleChevronUp} from "@fortawesome/free-solid-svg-icons";
import Collapse from 'react-bootstrap/Collapse';
import {round} from "lodash";

const MoneyRangeCard= ({obj, widthValue, heightValue, title,borderRadius, fontWeight, fontSize, lineHeight, color, fontStyle, borderColor, getValueOnChange}) => {
    const [open, setOpen] = useState(true);
    const min = useRef(10)
    const max = useRef(40)
    const step = useRef(10)
    useEffect(() => {
        if (obj) {
            min.current = obj[0]
            max.current = obj[1]
            step.current = round(((max.current - min.current) / 3), 2)
            // step.current = Math.ceil((max.current - min.current) / 3);
            max.current = step.current*3 + min.current
        }
    }, [obj])
    return (
        <Card style={{width: widthValue, height: heightValue, borderRadius: borderRadius, borderColor: borderColor}} className="mt-3 mb-3">
            <div className='d-flex flex-column mt-1 mb-4'>
                <Card.Title className='ms-4 me-4 mt-3 d-flex justify-content-between'>
                    <label className='banners-title d-flex' style={{fontWeight: fontWeight, fontSize: fontSize, lineHeight: lineHeight, color: color, fontStyle: fontStyle}}>{title}</label>
                    <button className='d-flex circle-up justify-content-center align-items-center'
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}>
                        <FontAwesomeIcon icon={open ? faCircleChevronUp : faCircleChevronDown}/>
                    </button>
                </Card.Title>
                <Collapse in={open}>
                    <div className='ms-4 me-4 justify-content-center align-items-center'>
                        <Form.Range step={step.current} min={min.current} max={max.current} size={10} name="range" onChange={getValueOnChange}/>
                        <div className="d-flex justify-content-between">
                            <label htmlFor="range" className="range-label">$ {min.current}</label>
                            <label htmlFor="range" className="range-label">$ {min.current + step.current}</label>
                            <label htmlFor="range" className="range-label">$ {min.current + step.current*2}</label>
                            <label htmlFor="range" className="range-label">$ {max.current}</label>
                        </div>
                    </div>
                </Collapse>
            </div>
        </Card>
    );
}

MoneyRangeCard.defaultProps = {
    widthValue: '100%',
    heightValue: 'auto',
    // heightValue: '222px',
    filter: 'drop-shadow(-5px 11px 10px rgba(0, 0, 0, 0.15))',
    borderRadius: '30px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#000000',
    borderColor: '#E2E2E2'
}

export default MoneyRangeCard;