import React from "react";
import Footer from "../../components/footer";
import NewsLetter from "../../components/newsLetter";
import HeaderMenu from "../../components/headerMenu";
import HomeBody from "../../components/homeBody";
import {CustomerContextProvider} from "../../context/customer/CustomerContext";


const HomeLogged = () => {
    return <div className="w-100">
        <HeaderMenu/>
        <CustomerContextProvider>
            <HomeBody/>
        </CustomerContextProvider>
        <NewsLetter/>
        <Footer/>
    </div>

}

export default HomeLogged;
