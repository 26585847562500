import React, {useEffect, useState} from "react";
import CardWithLogo from "../../../components/cardWithLogo";
import LogoM from "../../../assets/img/logo/logo_m.ico";
import {useAuth} from "../../../context/auth/AuthContext";
import {useNavigate} from "react-router-dom";
import {useParseRoute} from "../../../hooks/parseRoute";
import InputRound from "../../../components/inputRound";
import ButtonWhite from "../../../components/buttonWhite";
import {useApp} from "../../../context/app/AppContext";
import {useTranslation} from "react-i18next";
import {isNull} from "lodash";

const FormLogin = () => {
    const {t} = useTranslation(['auth', 'common'])
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const routeHome = useParseRoute('customer')
    const routeForgottenPassword = useParseRoute('auth.forgotten_password')
    const {contactNumber,setContactNumber, login, error, setError} = useAuth()
    const {language} = useApp()

    useEffect(() => {
        if (contactNumber.length === 0) {
            navigate('/')
            setError(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLogin = () => {
        setLoading(true)
        const params = Object.assign({}, {
            email: contactNumber,
            password: password,
            'select_language': language,
        })
        login(params).then(() => {
            navigate(routeHome)
        }).catch(() => {
            setLoading(false)
        })

    }

    const handleLinkForgottenPassword = () =>{
        setError(null)
        setContactNumber(null)
        navigate(routeForgottenPassword)
    }

    return <div className="container d-flex justify-content-center">
        <div className="d-flex flex-column" style={{marginTop: "200px"}}>
            <CardWithLogo logo={LogoM} className="d-flex flex-column" width={462} height={371}>
                <div className="w-75 text-center  mx-auto fs-2"
                     style={{fontWeight: 700, width: '400px', lineHeight: "38px"}}>
                    {t('auth:headerPassword')}
                </div>
                <div className="w-auto mx-auto mt-3">
                    {error && <span className="text-danger">{error}</span>}
                </div>
                <div className="w-auto mx-auto mt-2">
                    <InputRound onChange={setPassword} placeholder={t('auth:form.password')} type="password"
                                showErrors={!isNull(error)}/>
                </div>
                <div className="w-auto mx-auto mt-2 text-center cursor-pointer"
                     style={{fontWeight: 700, lineHeight: "21px", fontSize: "18px", color: "#378DF3"}}
                     onClick={handleLinkForgottenPassword}>
                    {t('auth:forgotPassword')}
                </div>
                <div className="w-auto mx-auto mt-5">
                    <ButtonWhite className="mx-auto" styleDefault={{marginTop: "16px"}} loading={isLoading}
                                 onClick={handleLogin}>
                        {t('common:accept')}
                    </ButtonWhite>
                </div>
            </CardWithLogo>
        </div>
    </div>
}

export default FormLogin;