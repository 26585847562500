import React, {useEffect} from 'react';
import './css/allStores.css'
import {useTranslation} from "react-i18next";
import {useCustomer} from "../../context/customer/CustomerContext";
import _map from "lodash/map";
import RestaurantCollectionCard from "../cards/restaurantCollectionCard";
import RestaurantCollectionCardSkeleton from "../cards/restaurantCollectionCardSkeleton";
import _isEmpty from "lodash/isEmpty";

const AllStoresComponent = () => {
    const {t} = useTranslation(['customer'])
    const {
        storeList,
        getStoreList,
    } = useCustomer()

    useEffect(() => {
        getStoreList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className="d-flex flex-column justify-content-start align-items-start">
                <label className="all-stores-title">{t('customer:allStores')}</label>
                <div className="row">
                    {
                        _map(_isEmpty(storeList)?Array(3):storeList, (value, index) => {
                            if (!value) {
                                return <div className='d-flex restaurantCollection' key={'restaurant-collection-' + index}>
                                    <RestaurantCollectionCardSkeleton></RestaurantCollectionCardSkeleton>
                                </div>
                            }
                            return <div className='restaurantCollection mt-3 me-3' key={'restaurant-collection-' + index}>
                                <RestaurantCollectionCard restaurantCollection={value}></RestaurantCollectionCard>
                            </div>
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default AllStoresComponent;