import React from "react";
import styled from "styled-components";

const FloatingCardContainer = styled.div`
  position: absolute;
  background: #FFFFFF;
  border-radius: 30px;
  ${props => props.widthValue && `width:`+props.widthValue};
  ${props => props.heightValue && `height:`+props.heightValue};
  ${props => props.leftValue && `left:`+props.leftValue};
  ${props => props.topValue && `top:`+props.topValue};
  ${props => props.rightValue && `right:`+props.rightValue};
  ${props => props.bottomValue && `bottom:`+props.bottomValue};
`

const FloatingCard = ({...props}) => {
    return <FloatingCardContainer {...props}>
        {props.children}
    </FloatingCardContainer>
}

export default FloatingCard;