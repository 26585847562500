import React, {useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import moment from "moment";
import Form from "react-bootstrap/Form";
import {useApp} from "../../context/app/AppContext";
import Facebook from "../../assets/img/icons/facebook_yellow.png"

const FooterContainer = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  width: 100%;
  min-height: 328px;
  background: #000000;
  color: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .copyright {
    align-self: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-transform: none;
  }

  .link-apk {
    border-radius: 4px;
    text-transform: none;
    color: #E2E2E2;
    width: 117px;
    height: 33.58px;
  }

  .link-apk i {
    font-size: 20px;
  }

  a {
    text-decoration: none;
    cursor: pointer !important;
  }

  .social-networks .social-network {
    width: 38.87px;
    height: 38.87px;
    border-radius: 50%;
  }

  .social-networks a:hover {
    color: #FFBF00
  }

  @media (max-width: 600px) {
    & > div {
      flex-direction: column;
    }

    & > div > div {
      align-self: center;
      margin-top: 10px;
    }
  }
`

const Footer = () => {
    const {t} = useTranslation(['footer', 'common', 'product'])
    const [appStoreLink] = useState(process.env.REACT_APP_APP_STORE_LINK ? process.env.REACT_APP_APP_STORE_LINK : '')
    const [googlePlayLink] = useState(process.env.REACT_APP_GOOGLE_PLAY_LINK ? process.env.REACT_APP_GOOGLE_PLAY_LINK : '')
    const [facebookLink] = useState(process.env.REACT_APP_FACEBOOK_LINK ? process.env.REACT_APP_FACEBOOK_LINK : '')
    const [instagramLink] = useState(process.env.REACT_APP_INSTAGRAM_LINK ? process.env.REACT_APP_INSTAGRAM_LINK : '')
    const [youtubeLink] = useState(process.env.REACT_APP_YOUTUBE_LINK ? process.env.REACT_APP_YOUTUBE_LINK : '')
    const {language, changeLanguage} = useApp()

    const handleChangeLanguage = (params) => {
        changeLanguage(params.target.value)
    }

    return <FooterContainer>
        <div className="w-100 h-auto d-flex justify-content-evenly" style={{margin: "55px 0"}}>
            <div className="w-auto h-auto">
                <label>{t('footer:downloadOurApp')}</label>
                <div className="d-flex justify-content-between mt-4" style={{width: "240px"}}>
                    <a href={appStoreLink} rel=" noreferrer noopener" target="_blank">
                        <div className="border-solid-white d-flex justify-content-evenly align-items-center link-apk">
                            <div className="w-auto h-auto">
                                <i className="bi bi-apple"></i>
                            </div>
                            <div className="d-flex flex-column cursor-pointer">
                                <label style={{fontSize: "8px", lineHeight: "8px"}}>
                                    {t('footer:downloadOnThe')}
                                </label>
                                <label className="cursor-pointer" style={{fontSize: "16px", lineHeight: "16px"}}>
                                    App Store
                                </label>
                            </div>
                        </div>
                    </a>
                    <a href={googlePlayLink} rel="noreferrer noopener" target="_blank">
                        <div className="border-solid-white d-flex justify-content-evenly align-items-center link-apk">
                            <div className="w-auto h-auto">
                                <i className="bi bi-google-play"></i>
                            </div>
                            <div className="d-flex flex-column cursor-pointer">
                                <label style={{fontSize: "8px", lineHeight: "8px"}}>
                                    {t('footer:downloadOnThe')}
                                </label>
                                <label className="cursor-pointer" style={{fontSize: "16px", lineHeight: "16px"}}>
                                    Google Play
                                </label>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div className="w-auto h-auto d-flex flex-column">
                <label className="mb-3">{t('product:products')}</label>
                <label className="mb-3">{t('common:links.aboutUs')}</label>
                <label className="mb-3">{t('common:links.services')}</label>
                <label className="mb-3">{t('common:links.blog')}</label>
            </div>
            <div className="w-auto h-auto d-flex flex-column">
                <label className="mb-3">{t('footer:followUs')}</label>
                <div className="d-flex justify-content-between social-networks" style={{width: "144.61px"}}>
                    <a href={facebookLink} target="_blank" rel="noreferrer" className="mandao-color">
                        <div
                            className="d-flex justify-content-center align-items-center border-solid-white social-network">
                            <img src={Facebook} alt="Facebook" style={{width: '8px', height: '16px'}}/>
                        </div>
                    </a>
                    <a href={youtubeLink} target="_blank" rel="noreferrer" className="mandao-color">
                        <div
                            className="d-flex justify-content-center align-items-center border-solid-white social-network">
                            <i className="bi bi-youtube" style={{fontSize: "20px"}}></i>
                        </div>
                    </a>
                    <a href={instagramLink} target="_blank" rel="noreferrer" className="mandao-color">
                        <div
                            className="d-flex justify-content-center align-items-center border-solid-white social-network">
                            <i className="bi bi-instagram" style={{fontSize: "20px"}}></i>
                        </div>
                    </a>
                </div>
                <label className="mb-3" style={{marginTop: "25px"}}>{t('common:language.label')}</label>
                <div style={{width: "150px"}}>
                    <Form.Select className="w-100 h-100" style={{borderRadius: "30px"}} onChange={handleChangeLanguage}
                                 defaultValue={language}>handleChangeLanguage
                        <option value="es">{t('common:language.es')}</option>
                        <option value="en">{t('common:language.en')}</option>
                    </Form.Select>
                </div>

            </div>
        </div>
        <label className="copyright"> {t('footer:copyright')} - {moment().year()}</label>
    </FooterContainer>
}

export default Footer;