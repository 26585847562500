import React, {useEffect, useState} from 'react';
import './css/filterOrder.css'
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {faClock, faStar} from "@fortawesome/free-regular-svg-icons";
import MoneyRangeCard from "../cards/moneyRangeCard";
import {useCustomer} from "../../context/customer/CustomerContext";
import CategoryCard from "../cards/categoryCard";

const FilterOrder = () => {
    const [order, setOrder] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [priceAmountProduct, setPriceAmountProduct] = useState(null)
    const [priceAmountDelivery, setPriceAmountDelivery] = useState(null)
    const [category, setCategory] = useState(null)
    const {t} = useTranslation(['customer'])
    const {
        getParametersFilter,
        parametersFilter,
        getStoreList,
    } = useCustomer()

    useEffect(() => {
        getParametersFilter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let filter_key_with_values = {
            order:order,
            is_open:isOpen,
            price_amount_product: priceAmountProduct,
            price_amount_delivery: priceAmountDelivery,
            category_id: category
        }
        let filtered = Object.fromEntries(Object.entries(filter_key_with_values).filter(([k,v]) => v!=null && v!==''));
        getStoreList(filtered)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, isOpen, priceAmountProduct, priceAmountDelivery, category])

    const orderFunction = (event) => {
        setOrder(event.target.value)
    }

    const isOpenFunction = (event) => {
        setIsOpen(event.target.checked)
    }

    const priceAmountProductFunction = (event) => {
        setPriceAmountProduct(event.target.value)
    }

    const priceAmountDeliveryFunction = (event) => {
        setPriceAmountDelivery(event.target.value)
    }

    const categoryFunction = (event) => {
        let result = category ? category.split(',') : []
        if (event.target.checked){
            result.push(event.target.value)
        } else {
            result = result.filter((v) => v !== event.target.value)
        }
        setCategory(result.toString())
    }

    return (
        <>
            <form>
                <div className="d-flex flex-column w-75 justify-content-center align-items-center p-lg-5">
                    <label className="filter-order-title p-5">{t('customer:sortBy')}</label>
                    <div className="d-flex w-100 justify-content-between mt-3 mb-3">
                        <div className="d-flex w-75">
                            <FontAwesomeIcon className="location-icon me-2" icon={faLocationDot} style={{color: "black"}}/>
                            <label className="filter-order-options">{t('customer:nearYou')}</label>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <input type="radio" className="filter-radio" name="order" value="default" onChange={orderFunction}/>
                        </div>
                    </div>

                    <div className="d-flex w-100 justify-content-between mt-3 mb-3">
                        <div className="d-flex w-75">
                            <FontAwesomeIcon className="star-icon me-2" icon={faStar} style={{color: "black"}}/>
                            <label className="filter-order-options">{t('customer:ratings')}</label>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <input type="radio" className="filter-radio" name="order" value="rating" onChange={orderFunction}/>
                        </div>
                    </div>

                    <div className="d-flex w-100 justify-content-between mt-3 mb-3">
                        <div className="d-flex w-75">
                            <FontAwesomeIcon className="clock-icon me-2" icon={faClock} style={{color: "black"}}/>
                            <label className="filter-order-options">{t('customer:time')}</label>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <input type="radio" className="filter-radio" name="order" value="time" onChange={orderFunction}/>
                        </div>
                    </div>

                    <div className="d-flex w-100 justify-content-between mt-3 mb-3">
                        <div className="d-flex">
                            <input type="checkbox" className="filter-checkbox me-2" name="is_open" onChange={isOpenFunction}/>
                            <label className="filter-order-options">{t('customer:openStore')}</label>
                        </div>
                    </div>

                    <MoneyRangeCard title={t('customer:priceRange')} obj={parametersFilter.price_amount_product} getValueOnChange={priceAmountProductFunction}></MoneyRangeCard>

                    <MoneyRangeCard title={t('customer:shippingCost')} obj={parametersFilter.price_amount_delivery} getValueOnChange={priceAmountDeliveryFunction}></MoneyRangeCard>

                    <CategoryCard title={t('customer:categories')} categories={parametersFilter.category} getCategoryOnChange={categoryFunction}></CategoryCard>
                </div>
            </form>
        </>
    )
}

export default FilterOrder;