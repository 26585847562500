import React from "react";
import styled from "styled-components";

const BaseInputRound = styled.input`
  border: 1px solid ${props => props.invalid ? '#FA0606FF' : '#E2E2E2'};
  border-radius: 30px;
  width: 323px;
  height: 57px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  ::placeholder {
    color: #969696;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #969696;
  }

  ::-ms-input-placeholder {
    color: #969696;
  }

  color: #000000;

  @media (max-width: 600px) {
    width: 100%;
  }
`

const InputRound = ({onChange, showErrors, ...props}) => {
    const handleOnChange = (e) => {
        e.preventDefault();
        onChange(e.target.value)
    }
    return <BaseInputRound {...props} invalid={showErrors} onChange={handleOnChange}>
        {props.children}
    </BaseInputRound>
}

export default InputRound;